import React from "react"

import { Icon } from "../Icon/Icon"
import { withSocialMedia } from "./withSocialMedia"
import { Wrapper, List, ListItem } from "./SocialMediaStyles"
import { Title } from "../Navigation/Footer/NavigationFooterStyles"

export const SocialMedia = withSocialMedia(({ title, items, layout }) => (
  <Wrapper layout={layout}>
    <Title>{title}</Title>
    <List>
      {items?.map(item => (
        <ListItem key={item?.title}>
          <a href={item?.url} rel="noreferrer" target="_blank">
            <Icon name={item?.title?.toLowerCase()} height={"16"} width={"16"} title={item?.title} />
          </a>
        </ListItem>
      ))}
    </List>
  </Wrapper>
))
