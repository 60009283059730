import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useSettings } from "./useSettings"
import { useEffect } from "react"

export const useNewsletter = path => {
  const {
    activeSubscribe,
    setActiveSubscribe,
    config: {
      settings: { keys, routes },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()
  const {
    newsletter,
    newsletterTemplates: { ...newsletterTemplates },
  } = useSettings()
  const { enabled, expiry, delay } = newsletter || {}

  const activeRoute =
    (enabled &&
      Object.entries(routes)
        ?.filter(([route, url]) => !route?.toLowerCase()?.includes(`page`) && url)
        ?.find(([, url]) => path?.includes(url))) ||
    (path === routes?.HOMEPAGE && [`HOMEPAGE`, routes.HOMEPAGE])
  const isActive = activeRoute && newsletterTemplates[`template${activeRoute[0]?.charAt(0)?.toUpperCase() + activeRoute[0]?.slice(1)?.toLowerCase()}`]

  useEffect(()=> {
    if (isActive && !storage.get(keys?.newsletter) && !activeSubscribe) {
      setTimeout(() => setActiveSubscribe(true), (delay || 5) * 1000)
    }
  },[])

  const hideSubscribe = () => {
    storage.set(keys?.newsletter, `hidden`, expiry)
    setActiveSubscribe(false)
  }

  return {
    activeSubscribe,
    hideSubscribe,
  }
}
