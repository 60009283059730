import React, { useState, useContext, createContext, cloneElement } from "react"

import { Dialog, DialogOverlay, DialogContent, Close } from "./ModalStyles"
import { Icon } from "../Icon/Icon"

const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args))

const ModalContext = createContext(null)

function Modal(props) {
  const [isOpen, setIsOpen] = useState(false)

  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />
}

function ModalExternal({ isOpen, setIsOpen, ...props }) {
  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />
}

function ModalDismissButton({ children: child }) {
  const [, setIsOpen] = useContext(ModalContext)
  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(false), child.props.onClick),
  })
}

function ModalOpenButton({ children: child }) {
  const [, setIsOpen] = useContext(ModalContext)
  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(true), child.props.onClick),
  })
}

function ModalContents({ layout, ...props }) {
  const [isOpen, setIsOpen] = useContext(ModalContext)
  return <Dialog layout={layout} isOpen={isOpen} onDismiss={() => setIsOpen(false)} {...props} />
}

function ModalContentsWithLayout({ children, layout, ...props }) {
  const [isOpen, setIsOpen] = useContext(ModalContext)
  return (
    <DialogOverlay layout={layout} isOpen={isOpen} onDismiss={() => setIsOpen(false)} {...props}>
      <DialogContent layout={layout}>
        <ModalDismissButton>
          <Close layout={layout}>
            <span>Close</span>
            <Icon name={"cross"} />
          </Close>
        </ModalDismissButton>
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}

export { Modal, ModalExternal, ModalDismissButton, ModalOpenButton, ModalContents, ModalContentsWithLayout }
