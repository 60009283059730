import React from "react"
import { Link } from "gatsby"

import { withAnnouncement } from "./withAnnouncement"
import { Outer, Inner, Item, Content } from "./AnnouncementStyles"

export const Announcement = withAnnouncement(({ items, active, className, enableAnnouncementBar, height, itemRefs }) => enableAnnouncementBar && (
  <Outer className={className} style={{ height }}>
    <Inner>
      {items?.map(({ link, title }, index) => (
        <Item key={title} active={active === index ? "true" : undefined} ref={ref => (itemRefs.current[index] = ref)}>
          {link?.url ? (
            <Link title={link?.title} to={link?.url}>
              {title}
            </Link>
          ) : (
            <Content>{title}</Content>
          )}
        </Item>
      ))}
    </Inner>
  </Outer>
))
