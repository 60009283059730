import tw, { styled } from "twin.macro"

const styles = {
  default: tw`w-full max-w-full mx-auto px-1 md:px-3 lg:relative`,
  header: tw`w-full max-w-full mx-auto px-1 md:px-3`,
  collapse: {
    "<md": tw`px-0 md:px-2-4`,
    "md>": tw`md:px-0`,
    "<lg": tw`px-0 lg:px-2-4`,
    "lg>": tw`px-2 lg:px-0`,
    default: tw`xl:px-0`,
  },
  spacing: {
    default: tw`pb-4 md:pb-8`,
    sm: tw`pb-4 md:pb-4-8`,
  },
  width: {
    xs: tw`max-w-xs hd:max-w-xxl`,
    sm: tw`max-w-sm hd:max-w-xxl`,
    md: tw`max-w-md hd:max-w-xxl`,
    lg: tw`max-w-lg hd:max-w-xxl`,
    xl: tw`max-w-xl hd:max-w-xxl`,
    default: tw`max-w-xl hd:max-w-xxl`,
  },
  background: {
    yellow: tw`bg-yellow`,
    black: tw`bg-black text-white`,
    transparent: tw`bg-transparent`,
    header: tw`text-white bg-black mlg:text-black mlg:bg-white`,
  },
}

export const BackgroundContainer = styled.section<BackgroundContainerProps>`
  ${tw`w-full`}
  ${({ background }) => (background ? styles.background[background] : null)}
`

export const StyledContainer = styled.div<ContainerProps>`
  ${({ layout }) => (layout && styles[layout] || styles[`default`])}
  ${({ collapse }) => (collapse && styles.collapse[collapse]) || (collapse && styles.collapse[`default`])}
  ${({ spacing }) => (spacing && styles.spacing[spacing]) || (spacing && styles.spacing[`default`])}
  ${({ width }) => styles.width[width] || styles.width[`default`]}
`

type ContainerProps = {
  collapse?: "<md" | "md>" | "<lg" | "lg>" | "default"
  spacing?: "default" | "sm"
  width?: "xs" | "sm" | "md" | "lg" | "xl" | "default"
}

type BackgroundContainerProps = {
  background: "yellow" | "black" | "transparent" | "header"
}
