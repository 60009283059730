import React from "react"
import ReactPlayer from "react-player/lazy"

import { Icon } from "../Icon/Icon"
import { Image } from "../Image/Image"
import { VideoWrapper, IconWrapper, VideoPlayerRatio, VideoPlayerWrapper, VideoPlayerInnerWrapper } from "./VideoStyles"
import { withVideo } from "./withVideo"

export const Video = withVideo(
  ({
     url,
     image,
     ratio,
     playing,
     handleVideoPlaying,
     alt,
     autoplay,
     controls,
   }) => (
    <VideoWrapper onClick={autoplay ? null : handleVideoPlaying}>
      <IconWrapper>
        {!playing && (
          <div>
            <Icon name={"video"} />
          </div>
        )}
      </IconWrapper>
      {!playing && image?.src && !autoplay ? (
        <Image image={image} alt={alt} ratio={ratio} />
      ) : (
        <VideoPlayerWrapper>
          <VideoPlayerRatio ratio={ratio} />
          <VideoPlayerInnerWrapper ratio={ratio}>
            <ReactPlayer
              playsinline={playing || autoplay}
              muted={playing || autoplay}
              volume={playing || autoplay ? 0 : null}
              url={url}
              playing={playing || autoplay}
              loop={playing || autoplay}
              width={`100%`}
              height={`100%`}
              controls={controls}
            />
          </VideoPlayerInnerWrapper>
        </VideoPlayerWrapper>
      )}
    </VideoWrapper>
  ),
)