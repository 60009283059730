import { useContext, useCallback } from "react"

import { useApp } from "./useApp"
import { LocationContext } from "../providers/location"

export const useLocation = () => {
  const location = useContext(LocationContext)
  const { config } = useApp()

  const userData = useCallback(async () => {
    const response = await fetch(config?.services?.location?.serviceUrl)
    const data = await response.json()

    return {
      ipData: data,
      userAgent: navigator.userAgent || "",
    }
  }, [config])

  return { ...location, userData }
}
