import React from "react"

import { Wrapper } from "./NavigationHeaderMobileSlideStyles"
import { withNavigationHeaderMobileSlide } from "./withNavigationHeaderMobileSlide"
import { NavigationHeaderMobileList } from "../List/NavigationHeaderMobileList"

export const NavigationHeaderMobileSlide = withNavigationHeaderMobileSlide(({ active, handleCloseMenu }) => (
  <Wrapper active={active}>
    <NavigationHeaderMobileList handleCloseMenu={handleCloseMenu} />
  </Wrapper>
))
