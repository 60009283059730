import React from "react"

import { withDiscountCode } from "./withDiscountCode"
import { Outer, Inner, Apply, Input, Error } from "./DiscountCodeStyles"

export const DiscountCode = withDiscountCode(
  ({
    discountCode,
    handleApply,
    handleChange,
    loading,
    error,
    additionalDiscountCodePlaceholder,
    additionalDiscountCodeSubmitingButtonText,
    additionalDiscountCodeSubmitButtonText,
    additionalDiscountCodeErrorMessage,
    layout,
  }) => (
    <Outer layout={layout}>
      <Inner>
        <Input type="text" value={discountCode} onChange={handleChange} placeholder={additionalDiscountCodePlaceholder} />
        <Apply onClick={handleApply}>{loading ? additionalDiscountCodeSubmitingButtonText : additionalDiscountCodeSubmitButtonText}</Apply>
      </Inner>
      {error && <Error>{additionalDiscountCodeErrorMessage}</Error>}
    </Outer>
  )
)
