import React, { useState, useEffect } from "react"

export const withVideo = Component => ({ name = "Video", autoplay = false, controls = false, externalPlaying = null, setExternalPlaying = null, ratio = undefined, ...rest }) => {
  const [playing, setPlaying] = useState(false)

  const handleVideoPlaying = () => {
    typeof setExternalPlaying === "function" ? setExternalPlaying(prev => !prev) : setPlaying(prev => !prev)
  }

  const formattedControls = autoplay ? controls : true

  const formattedRatio =
    typeof ratio === "object" && ratio?.width > 0 && ratio?.height > 0
      ? `${ratio.width}/${ratio.height}`
      : ratio

  useEffect(() => {
    setPlaying(autoplay)
  }, []);

  Component.displayName = name
  return <Component playing={externalPlaying ?? playing} controls={formattedControls} autoplay={autoplay} handleVideoPlaying={handleVideoPlaying} ratio={formattedRatio} {...rest} />
}
