import React from "react"
import { useSettings } from "../../../../../../hooks/useSettings";

export const withNavigationHeaderDesktopDropdown = Component => ({ name = "NavigationHeaderDesktopDropdown", item, active, scrolling }) => {
  const { items, type } = item
  const { announcementsData } = useSettings()
  const { enableAnnouncementBar } = announcementsData || {}

  Component.displayName = name
  return <Component items={items} type={type} active={active} scrolling={scrolling} enableAnnouncementBar={enableAnnouncementBar}/>
}
