import tw, { styled } from 'twin.macro'
import { NextArrow, PrevArrow } from '../Styled/Slider'

export const Outer = styled.div`
  ${tw`relative`}

  &:hover {
    .featured-image{
      ${tw`opacity-0`}
    }
  }
`

export const SliderWrapper = styled.div`
  ${tw`absolute inset-0 opacity-0 group-hover:opacity-100`}

  .card-prev,
  .card-next {
    ${tw`opacity-0 transition-opacity duration-300`}
    opacity: 0 !important;
  }

  &:hover {
    .card-prev,
    .card-next {
      ${tw`opacity-100 transition-opacity duration-300`}
      opacity: 1 !important;
    }
  }
`

export const FeaturedImageWrapper = styled.div`
  ${tw`relative`}
`

export const Prev = styled(PrevArrow)`
  ${tw`text-white`}
`

export const Next = styled(NextArrow)`
  ${tw`text-white`}
`

