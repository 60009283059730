import React from "react"

import { withAccountWidget } from "./withAccountWidget"
import { StyledIcon, StyledLink } from "./AccountWidgetStyles"
import { Body } from "../../Styled/Text"

export const AccountWidget = withAccountWidget(
  ({ url, additionalAccountText, layout }): JSX.Element => (
    <StyledLink title={additionalAccountText} to={url} layout={layout}>
      {layout !== "mobile" && <StyledIcon name={"account"} /> }
      {layout === "mobile" && <Body>{additionalAccountText}</Body>}
    </StyledLink>
  )
)
