import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

const fluidStyles = {
  end: tw`flex lg:justify-end`,
  start: tw`flex flex-wrap justify-center lg:justify-start lg:items-center`,
}

const halfStyles = {
  end: tw`flex lg:justify-end`,
  start: tw`flex flex-wrap justify-center lg:justify-start lg:items-center`,
}

export const Outer = styled.div`
  ${tw`py-2 px-2 lg:px-0 lg:pt-4 lg:pb-2 text-white relative bg-black`}
`

export const Inner = styled.div`
  ${tw`flex flex-col lg:flex-row justify-between w-full`}
  ${({ position }) => position === "bottom" && tw`pt-2 lg:pt-4`}}
`

export const Half = styled.div`
  ${tw`w-full lg:w-1/2`}
  ${({ align }) => align && halfStyles[align]}}
`

export const Fluid = styled.div`
  ${tw`flex-1`}
  ${({ align }) => align && fluidStyles[align]}}
  ${({ layout }) => layout === "bottomNav" && tw`flex flex-wrap justify-center lg:justify-start lg:items-center gap-0-6`}}
`

export const Quarter = tw.div`
  w-full lg:w-1/4
`

export const Third = tw.div`
  w-full lg:w-1/3
`

export const BottomNavItem = styled(Link)`
  ${tw`text-8 lg:text-12 lg:ml-2 hover:underline`}
  :after {
    content: "|";
    ${tw`pl-0-6 lg:hidden`}
  }
`

export const MegaNavigation = tw.nav`
  mb-4 lg:mb-0
`

export const MegaNavigationItem = styled.div`
  ${tw`text-white py-1 lg:py-1-4 block w-full border-b border-white relative text-10 lg:text-14`}
  :first-of-type {
    ${tw`border-t`}
  }
  a,
  svg {
    ${tw`block transform relative transition-all duration-300`}
  }
  svg {
    ${tw`opacity-0 absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-4 text-white`}
  }
  :hover {
    a {
      ${tw`pl-3`}
    }
    svg {
      ${tw`opacity-100 translate-x-0`}
    }
  }
`

export const NavWrapper = styled.div`
  ${tw`flex justify-between items-start md:justify-between`}

  & span[title="skidmanLogo"] {
    ${tw`lg:pt-4`}
    margin-top: -0.075rem;
  }

  @media (min-width: 768px) {
    & span[title="skidmanLogo"] {
      svg {
        width: 220px !important;
        height: 40px;
      }
    }
  }
`
