import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useRoutes } from "../../../hooks/useRoutes"
import { useSettings } from "../../../hooks/useSettings";

export const withAnnouncement = Component => ({ name = "Announcement", className, active: show, height, itemRefs }) => {
  const { announcementsData } = useSettings()
  const { duration, headerAnnouncements, enableAnnouncementBar } = announcementsData || {}

  const { linkResolver } = useRoutes()

  const [active, setActive] = useState(0)

  const length = headerAnnouncements?.length - 1

  useEffect(() => {
    if (length) {
      const interval = setInterval(() => {
        setActive(active => (active === length ? 0 : active + 1))
      }, (duration ?? 10) * 1000)
      return () => clearInterval(interval)
    }
  }, [duration, length])

  const items = headerAnnouncements.map(item => ({
    ...item,
    link: linkResolver(item?.link),
  }))

  Component.displayName = name
  return <Component items={items} active={active} className={className} show={show} enableAnnouncementBar={enableAnnouncementBar} height={height} itemRefs={itemRefs} />
}
