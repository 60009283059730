import React, { useCallback } from "react"

import { useApp } from "../../../hooks/useApp"
import { graphql, useStaticQuery } from "gatsby"
import { useSearch } from "@usereactify/search"

export const withInstantInner = Component => ({ name = "InstantInner" }: any) => {
  const { searchTerm, submitSearchTerm } = useSearch()
  const { globalStateReducer } = useApp()
  const { searchForm } = useStaticQuery(graphql`
    query SANITY_PAGE_SEARCH_NO_RESULTS {
      searchForm: sanityPageSearch {
        additionalViewAllResultsText
        noResults: _rawNoResults(resolveReferences: { maxDepth: 2 })
      }
    }
  `)

  const { additionalViewAllResultsText, noResults } = searchForm || {}

  const [, dispatch] = globalStateReducer

  const handleClose = useCallback(() => {
    dispatch({
      type: "HIDE_SEARCH",
    })
  }, [dispatch])

  Component.displayName = name
  return (
    <Component
      additionalViewAllResultsText={additionalViewAllResultsText}
      noResults={noResults}
      handleClose={handleClose}
      searchTerm={searchTerm}
      submitSearchTerm={submitSearchTerm}
    />
  )
}
