import tw, { styled } from "twin.macro"
import { LargeBody } from "../../Styled/Text"

export const Wrapper = styled.div`
  ${tw`max-w-24-5 md:max-w-40 mx-auto flex flex-col justify-center py-5`}
  div {
    ${tw`flex justify-center`}
  }
  a {
    ${tw`inline-block`}
  }
`

export const Title = styled(LargeBody)`
  ${tw`mb-2 text-center`}
`

export const Message = styled(LargeBody)`
  ${tw`text-center mb-3-2`}
`
