import React from "react"
import { Link } from "gatsby"

import { withBrand } from "./withBrand"
import { Wrapper } from "./BrandStyles"

export const Brand = withBrand(({ url, logo, layout, color }) => (
  <Wrapper as={Link} to={url} title={"homepage"} layout={layout} color={color}>
    <img style={{
      maxHeight: layout == "mobileNav" ? "20px" : "30px",
    }} className={"brand-logo"} src="/images/Drizabone_Wordmark_PRIMARY_NEGATIVE.png" alt="" />
  </Wrapper>
))
