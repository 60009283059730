import React from "react"

import { withSearchWidget } from "./withSearchWidget"
import { SearchButton, Text, StyledIcon } from "./SearchWidgetStyles"
import { Instant } from "../Instant/Instant"

export const SearchWidget = withSearchWidget(({ handleClick, additionalSearchText }) => (
  <>
    <SearchButton title={additionalSearchText} onClick={handleClick}>
      <StyledIcon name={"search"} height={14} />
    </SearchButton>
    <Instant />
  </>
))
