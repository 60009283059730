import tw, { styled } from "twin.macro"
import { Icon } from "../Icon/Icon"

const styles = {
  visible: tw`opacity-100 pointer-events-auto lg:translate-y-0`,
  hidden: tw`opacity-0 pointer-events-none`,
}

const MobileWrapperStyles = {
  visible: tw`visible`,
  hidden: tw`invisible`,
}

const DisplayStyles = {
  mobile: tw`block lg:hidden ml-2`,
  desktop: tw`hidden lg:block lg:mr-3`,
}

export const Currencies = styled.div`
  ${tw`lg:relative text-center text-inherit`}
  ${({ layout }) => layout && DisplayStyles[layout]}
`

export const ArrowDown = styled(Icon)`
  ${tw`absolute top-full left-1/2 transform -translate-x-1/2 -translate-y-2-8 opacity-0 transition duration-300`}
  ${({ show }) => show && tw`opacity-100 -translate-y-1-8`}
`

export const CurrencyButtons = styled.div`
  ${tw`lg:absolute lg:py-1 bg-white z-20 text-black lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-2 transition duration-300 w-9/10-vw h-8/10-vh lg:w-auto lg:h-auto`}
  ${({ visible }) => (visible && styles[visible]) || styles[`hidden`]}
`

export const CurrencyButton = styled.button`
  ${tw`py-3 flex items-center relative`}
  ${({ layout }) => layout === "list-item" && tw`px-2 py-2 lg:py-1 text-black hover:underline border-b border-grey-dim lg:border-none w-full`}
    ${({ active }) => active && tw`text-black`}
    white-space: nowrap;
  svg {
    margin-top: -3px;
  }
`

export const MobileWrapper = styled.div`
  ${tw`bg-black bg-opacity-75 fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center`}
  ${({ visible }) => visible && MobileWrapperStyles[visible]}
`

export const CloseButton = styled.button`
  ${tw`flex items-center text-white self-end mr-2 mb-2 text-12`}
  svg {
    ${tw`ml-1`}
  }
`

export const Radio = styled.div`
  ${tw`w-2 h-2 border border-grey-dim rounded-full relative ml-auto`}
  :before {
    content: "";
    ${tw`w-1 h-1 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-grey-dim opacity-0 rounded-full`}
    ${({ active }) => active && tw`opacity-100`}
  }
`
