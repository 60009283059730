import React from "react"
import { withMessage } from "./withMessage"
import { Wrapper } from "./MessageStyles"

interface MessageComponentProps {
  message: string;
  colour: string;
  layout: string;
  forceColour?: string;
}

const MessageComponent: React.FC<MessageComponentProps> = ({
  message,
  colour,
  layout,
  forceColour
}) => (
  message ? <Wrapper colour={colour} layout={layout} forceColour={forceColour}>
    {message}
  </Wrapper> : null
)

export const Message = withMessage(MessageComponent)