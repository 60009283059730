import React from "react"

import { withSubscribe } from "./withSubscribe"
import { Form, Title, Label, Email, SuccessMessage, InputWrapper, SubmitButton } from "./SubscribeStyles"
import { StyledFormErrors } from "../Styled/Form"

export const Subscribe = withSubscribe(
  ({
    layout,
    email,
    handleChange,
    handleSubmit,
    loading,
    errors,
    title,
    description,
    placeholder,
    submitButtonText,
    submittingButtonText,
    success,
    successMessage,
    successTitle,
    additionalSubscribeTitle,
  }): JSX.Element => (
    <Form layout={layout} onSubmit={handleSubmit} autoComplete="off">
      <Title as={"h2"} layout={layout}>
        {additionalSubscribeTitle}
      </Title>
      {!success ? (
        <>
          <Label layout={layout} htmlFor={"email"}>
            {description}
          </Label>
          <InputWrapper>
            <Email id={"email"} name="email" type="email" value={email} onChange={handleChange} placeholder={placeholder} required />
            <SubmitButton>{!loading ? submitButtonText : submittingButtonText}</SubmitButton>
          </InputWrapper>
        </>
      ) : (
        <SuccessMessage layout={layout}>{successMessage}</SuccessMessage>
      )}
      {errors && <StyledFormErrors>{errors}</StyledFormErrors>}
    </Form>
  )
)
