import React from "react"

import { withMenu } from "./withMenu"
import { MenuButton } from "./MenuStyles"
import { Icon } from "../../Icon/Icon"

export const Menu = withMenu(({ active, handleClick }) => (
  <MenuButton title={"menu"} onClick={handleClick} active={active}>
    <Icon name={active ? "cross" : "menu"} width={"16"} height={"14"} />
  </MenuButton>
))
