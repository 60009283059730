import tw, { styled } from "twin.macro"
import { RichTextContent } from "../Styled/Text"

const styles = {
  colour: {
    yellow: tw`border-yellow text-black`,
    white: tw`border-black text-black`,
    black: tw`border-black text-black`,
    grey: tw`border-black text-black`,
  },
  background: {
    white: tw`bg-white text-black border-black`,
    black: tw`bg-black text-white border-white`,
  },
}

export const Scroll = styled.div`
  ${({ colour }) => (colour && styles.colour[colour]) || styles.colour[`black`]}
  ${tw`overflow-y-hidden overflow-x-auto border m-2 border-t-0`}
`
export const Table = styled.div`
  ${({ colour }) => (colour && styles.colour[colour]) || styles.colour[`black`]}
  ${tw`table w-full border-t`}
`

export const Row = styled.div`
  ${tw`table-row -mx-1-2 items-center justify-between`}

  :first-of-type {
    ${tw`font-bold border-t-0`}
    ${({ colour }) => (colour && styles.background[colour]) || styles.background[`black`]}
    > div {
      ${tw`border-t-0`}
    }
  }
`
export const Column = styled.div`
  ${({ colour }) => (colour && styles.colour[colour]) || styles.colour[`black`]}
  ${tw`table-cell p-1-2 text-12 text-center border-t whitespace-nowrap`}
  border: 0.5px solid #000000;
  border-top-width: 1px;
  border-bottom: none;
  :first-of-type {
    ${tw`text-left`}
    border-left: none;
  }
  :last-of-type {
    border-right: none;
  }
`

export const Heading = tw.h3`
  text-18 md:text-18 leading-none p-2 table-caption border-t border-black
`

export const Description = styled(RichTextContent)`
  ${tw`border-t`}
  ${({ colour }) => (colour && styles.colour[colour]) || styles.colour[`black`]}
`
