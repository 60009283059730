import React from "react"

import { withBadge } from "./withBadge"
import { Wrapper } from "./BadgeStyles"

export const Badge = withBadge(({
  message,
  colour,
  layout
}) => (
    message ? <Wrapper colour={colour} layout={layout}>
      {message}
    </Wrapper> : null
  ))