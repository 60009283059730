import { useApp } from "./useApp"

export const useBadge = (tags) => {
  const {
    config: {
      settings: {
        tags: { badge }
      }
    }
  } = useApp()

  const message = tags?.find(tag => tag.includes(badge))?.split(":")[2]
  const colour = tags?.find(tag => tag.includes(badge))?.split(":")[1]

  return {
    message,
    colour
  }
}