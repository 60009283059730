import React from "react"
import { withImageVideo } from "./withImageVideo"
import { Wrapper, Desktop, Mobile } from "./ImageVideoStyles"
import { Image } from "../Image/Image"
import { Video } from "../Video/Video"

export const ImageVideo = withImageVideo(
  ({ desktop, desktopAlt, desktopVideo, mobile, mobileAlt, mobileVideo, ratio, spacing, externalPlaying, setExternalPlaying, loop, link, autoplay, controls, fullHeight, desktopAspectRatio, mobileAspectRatio }) => (
    <Wrapper spacing={spacing}>
      <Desktop fullHeight={fullHeight}>
        {desktopVideo ? (
          <Video
            externalPlaying={externalPlaying}
            setExternalPlaying={setExternalPlaying}
            url={desktopVideo}
            image={desktop}
            ratio={ratio ? ratio : desktop?.aspectRatio}
            alt={desktopAlt}
            loop={loop}
            autoplay={autoplay}
            controls={controls}
          />
        ) : (
          <Image image={desktop} ratio={desktopAspectRatio || ratio} alt={desktopAlt} link={link} />
        )}
      </Desktop>
      <Mobile>
        {mobileVideo ? (
          <Video
            externalPlaying={externalPlaying}
            setExternalPlaying={setExternalPlaying}
            url={mobileVideo}
            image={mobile}
            ratio={ratio ? ratio : mobile?.aspectRatio}
            alt={mobileAlt}
            loop={loop}
            autoplay={autoplay}
            controls={controls}
          />
        ) : (
          <Image image={mobile} ratio={mobileAspectRatio || ratio} alt={mobileAlt} link={link} />
        )}
      </Mobile>
    </Wrapper>
  )
)
