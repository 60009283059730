import tw, { styled } from "twin.macro"

import { Icon } from "../../Icon/Icon"
import { Body } from "../../Styled/Text"

export const SearchButton = styled.button`
  ${tw`relative flex flex-row items-center py-1-2 mlg:py-0-8 hover:underline mr-1-4 lg:mr-3`}
`

export const Text = styled(Body)`
  ${tw`hidden mlg:block`}
`

export const StyledIcon = styled(Icon)`
  svg {
    ${tw`w-auto h-auto hd:h-1-8`}
  }
`
