import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useApp } from "../../../hooks/useApp"
import { useMedia } from "../../../hooks/useMedia"

export const withCard = Component => ({ name = "Card", item, layout = "normal", collection = null, priceType = "card", isSlider = false, livePrice, isPriceLoaded }) => {
  const {
    globalStateReducer,
    config: {
      settings: { constraints },
    },
  } = useApp()

  const { isSmall } = useMedia()

  const { template } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_GIFT_CARD {
      template: sanityTemplateProduct {
        settingGiftCardProduct {
          shopify {
            handle: shopifyHandle
          }
        }
      }
    }
  `)

  const { settingGiftCardProduct } = template || {}

  const [{ activeCollectionCardView }] = globalStateReducer

  const { images, url, title, tags, defaultVariant, handle } = item

  const message = tags?.find(tag => tag.includes(`message`))?.split(":")[2]
  const messageColour = tags?.find(tag => tag.includes(`message`))?.split(":")[1]

  // File name format COM2222DR0_BLK_W_1
  const maleImages = images?.filter((image: any)  => {
    const ret = image?.src?.split('_')?.[2] == constraints?.MALE_VIEW
    return ret
    }
  )
  const femaleImages = images?.filter((image: any) => {
    const ret = image?.src?.split('_')?.[2] == constraints?.FEMALE_VIEW
    return ret
    }
  )
  
  
  // base case is unisex
  let mainImage = images?.[0]
  let hoverImage = images?.[1]
  
  if (activeCollectionCardView === constraints.MALE_VIEW) {
    mainImage = maleImages?.[0] || mainImage
    hoverImage = maleImages?.[1] || hoverImage
  }
  
  if (activeCollectionCardView === constraints.FEMALE_VIEW) {
    mainImage = femaleImages?.[0] || mainImage
    hoverImage = femaleImages?.[1] || hoverImage
  }
  
  const hidePriceForGiftCard = handle === settingGiftCardProduct?.shopify?.handle
  
  Component.displayName = name
  return (
    <Component
      images={images}
      featuredImage={mainImage}
      hoverImage={hoverImage}
      url={url}
      title={title}
      tags={tags}
      message={message}
      messageColour={messageColour}
      defaultVariant={livePrice || defaultVariant}
      layout={layout}
      collection={collection}
      hidePriceForGiftCard={hidePriceForGiftCard}
      priceType={priceType}
      isSlider={isSlider}
      handle={handle}
      isSmall={isSmall}
      isPriceLoaded={isPriceLoaded}
    />
  )
}
