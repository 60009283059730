import React from "react"

import { withHeader } from "./withHeader"
import { HeaderMasthead, HomeMargin, PanelLeft, PanelMiddle, PanelRight, StyledHeader, } from "./HeaderStyles"
import { NavigationHeaderDesktop } from "../Navigation/Header/Desktop/NavigationHeaderDesktop"
import { CartWidget } from "../Cart/Widget/CartWidget"
import { CartDrawer } from "../Cart/Drawer/CartDrawer"
import { AccountWidget } from "../Account/Widget/AccountWidget"
import { SearchWidget } from "../Search/Widget/SearchWidget"
import { Currency } from "../Currency/Currency"
import { Menu } from "./Menu/Menu"
import { StyledContainer } from "../Styled/Container"
import { Brand } from "./Brand/Brand"
import { Announcement } from "./Announcement/Announcement";

export const Header = withHeader(({ mobileNavigationLayout, desktopNavigationFormat, location, isScrolling, isSolid, updateHeaderSolid, currentRoute, enableAnnouncementBar, announcementHeight, announcementItemRefs, transparentHeader, isTransparentHeader, handleMouseEnter, handleMouseLeave, shouldToggleTransparancy }) => (
  <>
    {enableAnnouncementBar && <Announcement height={announcementHeight} itemRefs={announcementItemRefs} />}
    <StyledHeader scrolling={isScrolling} solid={shouldToggleTransparancy ? isSolid : true}>
      <StyledContainer collapse={"default"} layout={"header"}>
        <HeaderMasthead>
          <PanelLeft>
            <Menu mobileNavigationLayout={mobileNavigationLayout} />
            <Brand color={shouldToggleTransparancy ? (isSolid ? "yellow" : "white") : "yellow"} layout="desktop" />
          </PanelLeft>
          <PanelMiddle>
            <Brand color={shouldToggleTransparancy ? (isSolid ? "yellow" : "white") : "yellow"} layout="mobile" />
            <div onMouseEnter={() => handleMouseEnter() } onMouseLeave={() => handleMouseLeave()}>
              <NavigationHeaderDesktop isSolid={shouldToggleTransparancy ? isSolid : true} scrolling={isScrolling} desktopNavigationFormat={desktopNavigationFormat} location={location} />
            </div>
          </PanelMiddle>
          <PanelRight>
            <SearchWidget />
            <Currency layout={"desktop"}/>
            <AccountWidget />
            <CartWidget />
          </PanelRight>
        </HeaderMasthead>
      </StyledContainer>
    </StyledHeader>
    {(currentRoute?.pathname == "/" || transparentHeader) && <HomeMargin />}
    <CartDrawer />
  </>
))
