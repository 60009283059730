import tw, { styled } from "twin.macro"
import { Icon } from "../Icon/Icon"

export const Content = styled.div`
  ${tw`relative w-full flex items-center justify-center bg-black text-white text-center pt-3-8 md:pb-3-8 md:min-h-50 border-2 border-white`}
  ${({ isCookiesHidden }) => (!isCookiesHidden ? tw`pb-14` : tw`pb-8`)}
`

export const StyledIcon = styled(Icon)`
  ${tw`absolute top-0 right-0 pt-1-6 pr-1-6 text-white z-[100]`}
`
