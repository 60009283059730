import React, { useEffect, useState } from "react"

import { useCheckoutContext } from "../../../../hooks/useCheckout"

export const withCartItemPrice = Component => ({ name = "CartItemPrice", variant, discountMessage, discountAllocationsAmount }) => {
  const { checkout } = useCheckoutContext()

  const [price, setPrice] = useState("")
  const [compareAtPrice, setCompareAtPrice] = useState("")

  useEffect(() => {
    if (checkout?.currencyCode) {
      const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: checkout?.currencyCode,
        currencyDisplay: 'narrowSymbol'
      });

      const price = variant?.priceV2?.amount || variant?.priceV2 || 0
      const compareAtPrice = variant?.compareAtPriceV2?.amount || variant?.compareAtPriceV2 || 0

      setPrice(formatter.format(price))
      setCompareAtPrice(compareAtPrice)
    }
  }, [checkout?.currencyCode, variant])

  const formattedPrice = price
  const formattedComparedAtPrice = compareAtPrice ? `$${Number(compareAtPrice)}` : ""

  const isOnSale = Number(variant?.compareAtPriceV2?.amount || variant?.compareAtPriceV2 || 0) > Number(variant?.priceV2?.amount || variant?.priceV2 || 0)

  Component.displayName = name
  return <Component isOnSale={isOnSale} price={formattedPrice} compareAtPrice={formattedComparedAtPrice} discountMessage={discountMessage} />
}
