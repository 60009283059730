import tw, { styled } from "twin.macro"

import { StyledInput } from "../Styled/Form"
import { StyledButton } from "../Styled/Button"

export const Form = styled.form`
  ${tw`flex flex-col`}
  ${({ layout }) => {
    if (layout === "newsletter" || layout === "password") return tw`max-w-50 px-2 mx-auto`
    if (layout === "footer") return tw`mb-0-8 md:mb-2-4 md:max-w-2/3 pt-2 lg:py-0`
    if (layout === "footer-desktop") return tw`hidden lg:block mb-0-8 md:mb-2-4 md:max-w-2/3 pt-2 lg:py-0`
    if (layout === "footer-mobile") return tw`lg:hidden mb-0-8 md:mb-2-4 md:max-w-2/3 pt-2 lg:py-0`
  }}
`

export const Title = styled.h2`
  ${({ layout }) => {
    if (layout === "newsletter") return tw`text-30 md:text-60 mb-1-8 text-white`
    if (layout === "password") return tw`text-18 md:text-30 md:leading-none mb-1-8 text-white`
    if (layout === "footer" || layout === "footer-desktop" || layout === "footer-mobile") return tw`text-white mb-1 text-12`
  }}
`

export const Label = styled.label`
  ${({ layout }) => {
    if (layout === "newsletter" || layout === "password") return tw`text-12 md:text-18 pb-1-2 md:pb-1-8`
    if (layout === "footer" || layout === "footer-desktop" || layout === "footer-mobile") return tw`block text-10 md:text-12 pb-0-8 mb-1 leading-19`
  }}
`

export const Email = styled(StyledInput)`
  ${tw`text-12 py-1-2 bg-transparent border border-r-0 border-white`}
`

export const SuccessMessage = styled.p`
  ${({ layout }) => {
    if (layout === "newsletter") return tw`text-12 md:text-18`
    if (layout === "footer" || layout === "footer-desktop" || layout === "footer-mobile") return tw`text-12`
  }}
`

export const InputWrapper = tw.div`
  flex flex-row mb-1
`

export const SubmitButton = styled(StyledButton)`
  ${tw`border uppercase tracking-1.5 text-10 px-2`}
`
