import tw, { css, styled } from "twin.macro"

export const Outer = styled.div`
  ${tw`fixed top-0 left-0 right-0 z-50 lg:py-4 h-full lg:h-auto min-w-full border-t text-black bg-white min-h-14 lg:flex items-center transition-all duration-300 transform overflow-y-auto overflow-x-hidden`}
  ${({ active }) => (active ? tw`opacity-100 visible pointer-events-auto translate-y-0` : tw`opacity-0 invisible pointer-events-none -translate-y-20`)}
  ${css`
    height:var(--inner-height)
  `}
`

export const Inner = styled.div`
  ${tw`max-w-xl mx-auto flex flex-col w-full px-2`}
  ${css`
    .instant-search-results {
      grid-template-columns: repeat(2, 1fr) !important;
      gap: 0.625rem !important;
      
      @media only screen and (min-width: 1282px) {
        display: flex !important;
        
        .group {
          flex: 1;
          max-width: 221px;
        }
      }
    }
  `}
`

export const Header = tw.header`
  flex items-center lg:hidden px-2 w-full relative min-h-8
`

export const Close = tw.button`
  mr-auto transform translate-x-0 absolute top-1/2 -translate-y-1/2 transition-all duration-300
`

export const ButtonWrapper = tw.div`
  mt-2 mb-2
`

export const NoResults = styled.div`
  ${tw`p-2`}
  a {
    ${tw`text-red underline`}
  }
  p {
    ${tw`text-12`}
  }
`
