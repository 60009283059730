import React from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import { Image } from "../Image/Image"

import { Outer, SliderWrapper, FeaturedImageWrapper, Prev, Next } from './CardImageSliderStyles'
import { Icon } from '../Icon/Icon'

SwiperCore.use([Navigation])

type CardImageSliderProps = {
  images: any
  handle: string,
  featuredImage: any
}

const CardImageSlider: React.FC<CardImageSliderProps> = ({ images, handle, featuredImage }) => {
  return (
    <Outer className="group">
      <FeaturedImageWrapper className="featured-image">
        <Image image={featuredImage} ratio="8/11"></Image>
      </FeaturedImageWrapper>
      <SliderWrapper>
        <Prev id={`CTA-prev-${handle}`} className="card-prev" title={"previous image"}>
          <Icon name={"chevronLeft"}  width={16} height={28} />
        </Prev>
        <Next id={`CTA-next-${handle}`} className="card-next" title={"next image"}>
          <Icon name={"chevronRight"} width={16} height={28} />
        </Next>
        <Swiper
          navigation={{
            prevEl: `#CTA-prev-${handle}`,
            nextEl: `#CTA-next-${handle}`,
          }}
          lazy={true}
          preloadImages={false}
          slidesPerView={1}
          initialSlide={1}
        >
          {images.length > 0 ? (
            images.map(img => (
              <SwiperSlide key={img?.alt}>
                {img?.src ? (
                  <Image key={img?.alt} image={img} ratio={"8/11"} />
                ) : null }
              </SwiperSlide>
            ))
          ) : null }
        </Swiper>
      </SliderWrapper>
    </Outer>
  )
}

export default CardImageSlider

