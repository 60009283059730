import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const Outer = styled.div`
  ${({ layout }) => {
    if (layout === "drawer") return tw`bg-white`
    if (layout === "cart") return tw`rounded-5 px-2-4 md:px-3-2`
  }}
  ${tw`bg-beige mb-1-6 md:mb-3-2 pt-2-8 pb-2-4`}
`

export const Inner = tw.div`
  flex flex-row
`

export const Input = styled.input`
  ${tw`bg-transparent flex-1 border-b pb-0-8`}
  ${css`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      ${tw`m-0`}
      -webkit-appearance: none;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
    &::placeholder {
      ${tw`text-black opacity-100 text-12 `}
    }
  `}
`

export const Apply = tw.button`
  border px-0-8 w-11 ml-0-8 md:ml-1-2 text-12 py-0-6 md:hover:bg-black md:hover:text-white transition-all duration-200
`

export const Error = tw.div`
  text-12 mt-1-6 text-red px-2-4 md:px-3-2 
`
