import { useEffect, useState, useMemo } from "react"
import { useShopify } from "./useShopify"
import { useResults } from "@usereactify/search"

export const useLivePrices = () => {
  const [prices, setPrices] = useState([])
  const { getProducts } = useShopify()
  const { results } = useResults()

  const handles = useMemo(() => results?.filter(result => result.type === 'product').map(result => result.handle), [results])

  useEffect(() => {
    const fetchPrices = async () => {
      return await getProducts({
        handles,
        firstVariants: 1,
        firstImages: 0,
        firstCollections: 0,
        firstMedia: 0,
        firstMetafields: 0,
      }).then(res => {
        const variants = res
          ?.map(product =>
            product?.variants?.map(variant => ({
              id: product?.id,
              priceV2: variant?.priceV2,
              compareAtPriceV2: variant?.compareAtPriceV2,
            }))
          )
          .flat()

        setPrices(variants)
      })
    }
    fetchPrices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  return { prices }
}
