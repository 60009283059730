import React from "react"

import { withPrice } from "./withPrice"
import { Wrapper, PriceTag } from "./PriceStyles"

export const Price = withPrice(({ isOnSale, price, compareAtPrice, layout }) => !price.includes('NaN') && (
  <Wrapper>
    {isOnSale ? (
      <>
        <PriceTag strike={"true"} layout={layout}>
          {compareAtPrice}
        </PriceTag>
        &nbsp;
      </>
    ) : null}
    <PriceTag sale={isOnSale ? "true" : "false"} layout={layout}>
      {price}
    </PriceTag>
  </Wrapper>
))
