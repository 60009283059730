import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const StyledOl = styled.ol`
  ${tw`list-none ml-2 list-decimal`}
  li {
    ${tw`mb-1`}
  }
`
export const StyledUl = tw.ul`list-disc list-outside ml-2`
export const StyledLi = styled.li<LiProps>`
  ${({ level }) => !level && tw`mb-1 last:mb-0`}
  ${({ level }) =>
    level !== 2 &&
    css`
      &::before {
        content: "" !important;
      }
    `}
    ${({ level }) =>
      level &&
      css`
        > ul,
        > ol {
          ${tw`ml-2`}
        }
      `}
`

type LiProps = {
  level: number
}
