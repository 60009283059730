import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

import { Body } from "./Text"

export const StyledForm = tw.form`block w-full`

export const StyledFormErrors = tw(Body)`my-0-8 text-red`

export const StyledLabel = tw.label`
  text-12 block mb-0-8
`

export const StyledInput = styled.input<InputProps>`
  ${tw`block appearance-none outline-none w-full border bg-transparent border-grey text-12 px-1-2 py-0-8 transition-all duration-200 placeholder-black placeholder-opacity-100`}
  ${({ spacing }) => {
    if (spacing === "true") return tw`mb-2`
    if (spacing === "small") return tw`mb-1-2`
    return tw`mb-0`
  }}
  ${({ first }) => (first ? tw`md:mr-1-2` : tw`md:mr-0`)}
  ${({ last }) => (last ? tw`md:ml-1-2` : tw`md:ml-0`)}
  ${({ disabled }) => disabled && tw`pointer-events-none bg-beige placeholder-grey`}
  ${({ error }) => error && tw`border-red`}
  ${({ success }) => success && tw`border-green`}
  ${css`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      ${tw`m-0`}
      -webkit-appearance: none;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
  `}
`

export const StyledTextArea = styled.textarea<TextareaProps>`
  ${tw`focus:outline-none bg-transparent w-full border border-grey min-h-15 placeholder-grey placeholder-opacity-100 py-0-8 px-1-2 text-12`}
  ${({ disabled }) => disabled && tw`pointer-events-none opacity-50`}
  ${({ spacing }) => {
    if (spacing === "small") return tw`mb-1-2`
    return tw`mb-0`
  }}
`

// TODO -- replace native select
export const StyledSelect = styled.select<SelectProps>`
  ${({ layout }) => {
    if (layout === "productVariant") return tw`py-1 px-1-6 text-12 leading-none rounded-5`
    if (layout === "cardVariant") return tw`py-0-2 px-0-8 md:text-12 text-18 leading-none md:leading-none rounded-2`
    else return tw`px-0-8 py-0-5 rounded-2 leading-none text-18`
  }}
  ${tw`block appearance-none outline-none w-full bg-transparent border text-black cursor-pointer`}
`

export const StyledDropdownSelect = styled.select<DropdownSelectProps>`
  ${({ layout }) => {
    if (layout === "form") return tw`py-1-6 text-18 md:text-12 leading-none md:leading-none`
    if (layout === "sortBy") return tw`text-18 md:text-12 pb-0-6 md:pb-1-2 leading-none md:leading-none text-black`
    if (layout === "address") return tw`pb-1-6 pt-2-6 text-18 leading-none border-black border-opacity-50`
  }}
  ${tw`block appearance-none outline-none w-full bg-transparent rounded-none border-b cursor-pointer uppercase `}
  -webkit-appearance: none;
  &:invalid {
    ${({ layout }) => {
      if (layout === "form") return tw`text-black uppercase text-18 `
    }}
  }
  [disabled] {
    ${({ layout }) => {
      if (layout === "form") return tw`text-black uppercase text-18 `
    }}
  }
`

export const StyledSelectOption = tw.option``

export const StyledSelectWrapper = styled.div<SelectWrapperProps>`
  ${({ spacing }) => (spacing === "true" ? tw`mb-0-8` : tw`mb-0`)}
  ${tw`relative w-full`}
`

export const StyledInputWrapper = styled.div<InputWrapperProps>`
  ${tw`w-full relative transition-all duration-200`}
  ${({ loading }) => loading && tw`opacity-50 pointer-events-none`}
`

export const StyledInputIconWrapper = styled.button`
  ${tw`absolute bottom-0 top-0 right-0 h-full flex items-center justify-center hover:translate-x-0-4 transform duration-300`}
  ${({ onClick, type }) => !onClick && type !== `submit` && tw`pointer-events-none`}
`

type InputProps = {
  spacing?: "true" | "false"
  first?: boolean
  last?: boolean
  disabled?: boolean
  error?: boolean
  success?: boolean
}

type TextareaProps = {
  spacing?: "true" | "false"
  disabled?: boolean
  error?: boolean
}

type InputWrapperProps = {
  loading?: boolean
}

type SelectProps = {
  layout?: "productVariant" | "cardVariant"
}

type DropdownSelectProps = {
  layout: "sortBy" | "form" | "address"
}

type SelectWrapperProps = {
  spacing?: "true" | "false"
}
