import React, { useCallback, useEffect, useState } from "react"
import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useSettings } from "./useSettings"

export const useMeta = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const { helpers } = useCore()
  const { languages, organisation, scripts, social, tracking } = useSettings()
  const { isBrowser, isDomReady } = helpers
  const [ trackingReady, setTrackingReady ] = useState(false)

  const mapping = {
    page: helpers.schemaWebSite,
    blog: helpers.schemaBlog,
    article: helpers.schemaArticle,
    product: helpers.schemaProduct,
    collection: helpers.schemaWebSite,
    search: helpers.schemaSearch,
    store: helpers.schemaStore,
  }

  const formatTitle = (title, siteName, separator) => {
    if (separator?.length && title?.length && siteName?.length && !title.includes("|")) {
      return `${title} ${separator} ${siteName}`
    }
    return title
  }

  const getData = ({ breadcrumbs, data, language, routes, template, url }) => {
    const schema = helpers.schemaData(
      { breadcrumbs, data, global: undefined, language, organisation, routes, social, template, tracking, url },
      mapping
    )
    const shopify = data?.document?.shopify || data?.collection?.shopify || data?.product?.shopify
    const raw = JSON.parse(shopify?.raw || "{}")
    const shopifySeoDescription = raw?.metafields?.find(({ key }) => key === "description_tag")?.value
    const shopifySeoTitle = raw?.metafields?.find(({ key }) => key === "title_tag")?.value

    const title = shopifySeoTitle || shopify?.title || data?.title || schema.title

    if (shopify) {
      schema.title = formatTitle(title, organisation?.title, organisation?.separator)
      schema.description = shopifySeoDescription || shopify?.description || schema.description
    }

    return schema
  }

  const getTags = data => helpers.metaTags(data)

  const getLanguages = route =>
    languages?.hrefLangs?.map(({ _key, language, region, url }, index) => ({
      key: _key,
      primary: !index,
      href: `${url || organisation?.url}${route || ``}`,
      hrefLang: `${language}-${region}`,
      language: `${language}_${region}`,
      rel: `alternate`,
    }))

  // Disabled helpers.schemaContent because it was causing an error with the id
  const schemaProduct = ({ title, description, url, image, siteURL, document }) => {
    return {
      "@context": "http://schema.org",
      "@type": "Product",
      name: title,
      url: url,
      description: description,
      gtin13: (document.id.includes("gid://shopify/Product/") ? document.id : atob(document?.id))?.replace("gid://shopify/Product/", ""),
      image: image,
      brand: {
        "@type": "Thing",
        name: document?.vendor,
      },
      sku: document?.variants && document?.variants.length ? document?.variants[0].sku : "",
      offers: {
        "@type": "AggregateOffer",
        highPrice: document?.priceRange?.maxVariantPrice?.amount || "",
        lowPrice: document?.priceRange?.minVariantPrice?.amount || "",
        priceCurrency: document?.priceRange?.maxVariantPrice?.currencyCode || "",
        offerCount: document?.variants?.length,
        offers: document?.variants
          ? document?.variants?.map(variant => schemaOffer({ siteURL, variant: { ...variant }, handle: document.handle }))
          : [],
      },
      keywords: [],
    }
  }

  const schemaOffer = ({ siteURL, variant, handle }) => ({
    "@type": "Offer",
    availability: `http://schema.org/${variant?.availableForSale ? `InStock` : `OutOfStock`}`,
    price: variant?.priceV2?.amount || "",
    priceCurrency: variant?.priceV2?.currencyCode || "",
    sku: variant?.sku,
    url: `${siteURL}/products/${handle}?variant=${(variant?.id?.includes("gid://shopify/ProductVariant/") ? variant?.id : atob(variant?.id))?.replace(
      "gid://shopify/ProductVariant/",
      ""
    )}`,
  })

  const getSchemas = data => {
    const dataContent = {
      ...data,
      document: {
        ...data.document,
        id: btoa(data.document.id),
      },
    }
    try {
      if (data.type === "product") {
        data.image = data?.image || {}
        data.image.url = data?.document?.featuredImage?.originalSrc
      }
    } catch {
      // nothing
    }

    const schemas = [
      [
        helpers.schemaOrg(data),
        data.type !== "product" ? helpers.schemaContent(dataContent) : schemaProduct(dataContent),
        helpers.schemaBreadcrumbs(data),
      ]?.filter(schema => schema[`@type`] !== `Organization` || data?.url === routes?.HOMEPAGE),
    ]
    return schemas
  }

  useEffect(() => {
    if (!trackingReady && isBrowser && isDomReady){
      setTimeout(() => {
        setTrackingReady(true)
      }, 2500)
    }
  }, [trackingReady, isBrowser, isDomReady])

  const getTracking = useCallback(() => (
    trackingReady ? (
      [
        helpers.renderTrackingScripts(scripts?.trackingHeader?.code || "", { id: "tracking", placement: "head" }),
        helpers.renderTrackingScripts(scripts?.trackingBody?.code || "", { id: "tracking", placement: "body" }),
        helpers.renderTrackingScripts(scripts?.trackingFooter?.code || "", { id: "tracking", placement: "foot" }),
      ]
    ) : []
  ), [trackingReady]);

  return {
    getData,
    getLanguages,
    getSchemas,
    getTags,
    getTracking,
  }
}
