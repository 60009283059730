import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Img from "gatsby-image"
import { Link } from "gatsby"

import { Clickable } from "./ImageStyles"
import { withImage } from "./withImage"

export const Image = withImage(
  ({ alt, handleClick, image, link, fullHeight, aspectRatio, ...props }) => (
    <Clickable
      as={link?.url && (link?.isExternal ? `a` : Link)}
      href={link?.isExternal && link?.url}
      onClick={handleClick}
      target={link?.external && `_blank`}
      title={link?.title}
      to={!link?.isExternal && link?.url}
      stretch={fullHeight ? "true" : "false"}
    >
      {image?.dimensions ? (
        <GatsbyImage
          image={image}
          alt={alt}
          style={{ height: "100%", width: "100%", pointerEvents: "none" }}
          {...props}
        />
      ) : (
        <Img
          fluid={image}
          alt={alt}
          style={{ height: "100%", width: "100%", pointerEvents: "none" }}
          {...props}
        />
      )}
    </Clickable>
  ),
)
