import React from "react"
import { Global, css } from "@emotion/react"
import tw, { styled } from "twin.macro"

import "swiper/swiper-bundle.min.css"
import "@reach/dialog/styles.css"

import { useCore } from "../../hooks/useCore"
import { useApp } from "../../hooks/useApp"

export const LayoutStyles = () => {
  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const isCookiesHidden = storage.get(keys?.cookies) === "hidden"

  return (
    <Global
      styles={css`
        * {
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          box-sizing: border-box;
          &:focus {
            outline: none !important;
          }
          input,
          textarea {
            border-radius: 0;
          }
          input,
          textarea,
          select {
            -webkit-appearance: none;
          }
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
        body {
          ${tw`text-12 font-body text-black bg-white break-words h-full-vh overflow-x-hidden`}
        }

        .filter-range-radio + .filter-range-label {
          span {
            ${tw`leading-none`}
          }
          ${tw`mb-1-6 mt-0`}
        }

        .filter-range-list {
          padding-bottom: 0 !important;
        }

        .filter-range-radio + .filter-range-label {
          ::before {
            ${tw`rounded-2 bg-transparent h-1-6 w-1-8 box-border border border-black my-0 ml-0 mr-1-6`}
          }
          ::after {
            content: "";
            ${tw`absolute top-0-9 left-0-6 w-0-4 h-0-8 border box-border border-white transition-all duration-200 rounded-none`}
            border-width: 0 2px 2px 0;
            transform: rotate(45deg) scale(0);
          }
        }
        .filter-range-radio:checked + .filter-range-label {
          ::before {
            ${tw`bg-black`}
          }
          ::after {
            transform: rotate(45deg) scale(1);
          }
        }
        #gorgias-web-messenger-container {
          ${tw`z-20`}
        }
        @media (min-width: 1024px) {
          #chat-window {
            ${!isCookiesHidden ? "bottom: 140px !important;" : null}
          }
        }
        #chat-button {
          ${tw`z-20`}
          ${!isCookiesHidden ? "bottom: 60px !important;" : null}
        }
      `}
    />
  )
}

export const Body = styled.div`
  ${tw`w-full transition-all transform duration-500`}
  ${({ activeMenu }) => (activeMenu ? tw`-translate-x-30 relative overflow-y-hidden` : `overflow-y-auto`)}
`

export const Aside = styled.div`
  ${tw`w-30 -right-30 fixed h-full transition-all transform duration-500`}
  ${({ activeMenu }) => (activeMenu ? tw`-translate-x-30 z-50` : tw``)}
`

export const Overlay = styled.div`
  ${tw`fixed bg-black flex inset-0 cursor-pointer duration-300 opacity-50 transition-all z-50 max-h-full-vh h-full`}
`

export const Close = tw.button`
  absolute top-1/2 right-1-2 text-black
`
