import tw, { styled } from "twin.macro"

export const VideoWrapper = styled.div`
  ${tw`relative w-full h-full focus:outline-none cursor-pointer`}
`

export const IconWrapper = styled.button`
  ${tw`absolute inset-0 w-full h-full flex items-center justify-center focus:outline-none`}
  span {
    ${tw`z-10`}
  }
`

export const VideoPlayerWrapper = tw.div`
  relative w-full 
`

export const VideoPlayerRatio = styled.div<VideoPlayerRatioProps>`
  ${tw``}
  padding-bottom: ${({ ratio }) => {
    if (typeof ratio === "string") return (Number(ratio.split("/")[1]) / Number(ratio.split("/")[0])) * 100
    if (typeof ratio === "number") return (1 / ratio) * 100
  }}%;
`

export const VideoPlayerInnerWrapper = styled.div`
  ${tw`inset-0 w-full h-full`}
  ${({ ratio }) => (ratio ? tw`absolute` : tw`relative`)}
`

type VideoPlayerRatioProps = {
  ratio: string | number
}
