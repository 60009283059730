import tw, { styled } from "twin.macro"
import { Dialog as ReachDialog, DialogOverlay as ReachDialogOverlay, DialogContent as ReachDialogContent } from "@reach/dialog"

export const Dialog = styled(ReachDialog)``

export const DialogOverlay = styled(ReachDialogOverlay)`
  ${tw`bg-black bg-opacity-75 z-50`}
  ${({ layout }) => {
    switch (layout) {
      default:
        return null
    }
  }}
`

export const DialogContent = styled(ReachDialogContent)`
  ${({ layout }) => {
    switch (layout) {
      case "productImages":
        return tw`max-h-full-vh w-full md:max-w-90 p-0 md:my-0`
      case "newsletter":
        return tw`max-h-full-vh p-0 w-full md:w-63 m-0 mx-auto md:mt-12 md:mb-1/10-vh top-full sticky md:static`
      case "backInStock":
        return tw`max-h-full-vh p-0 w-full md:w-45-4 m-0 mx-auto md:mt-12 md:mb-1/10-vh top-full sticky md:static top-0`
      case "sizeGuide":
        return tw`bg-white p-2 md:p-6 w-full md:w-90 m-0 mx-auto md:mt-12 md:mb-1/10-vh top-full sticky md:relative top-0`
      case "sizeGuideAlt":
        return tw`bg-white p-6 w-full md:w-90 m-0 mx-auto md:mt-12 md:mb-1/10-vh top-full sticky md:relative top-0 overflow-y-visible`      
      case "locationConfirmation":
        return tw`max-h-full-vh w-full lg:max-w-50 p-4 relative overflow-y-visible`
      default:
        return null
    }
  }}
`

export const Close = styled.button`
${({ layout }) => {
  switch (layout) {
    case "sizeGuide":
      return tw`absolute flex flex-row items-center text-white top-1 md:-top-2-6 right-1 md:right-0 z-50 text-black md:text-white`
    case "sizeGuideAlt":
      return tw`absolute flex flex-row items-center text-white -top-2-6 right-0-8 lg:right-0 z-50`    
    case "locationConfirmation":
      return tw`absolute flex flex-row items-center text-white -top-2-6 right-1 lg:right-0 z-50`    
    default:
      return tw`absolute flex flex-row items-center md:text-black top-1-2 right-1-2 z-50`
  }
  }}
  span:first-of-type {
    ${tw`mr-1-2 hidden md:block`}
  }
`
