import { useStaticQuery, graphql } from "gatsby"

export const useSettings = () =>
  useStaticQuery(graphql`
    query SANITY_SETTINGS {
      appearance: sanitySettingAppearance {
        themeDisplay
        themeColour {
          hex
        }
        themeBgColour {
          hex
        }
      }
      announcementsData: sanitySettingAnnouncement {
          duration
          enableAnnouncementBar
          headerAnnouncements {
              title
              link: _rawLink(resolveReferences: { maxDepth: 2 })
          }
      }
      forms: allSanitySettingForms {
        edges {
          node {
            id: _id
            title
            slug {
              current
            }
            subject
            types {
              title
              recipients
            }
          }
        }
      }
      languages: sanitySettingLanguages {
        hrefLangs: languages {
          _key
          language
          region
          url
        }
      }
      currencies: allSanitySettingCurrencies {
        edges {
          node {
            languages {
              name
              currencyCode
              symbol
              redirectUrl
            }
          }
        }
      }
      maintenance: sanitySettingMaintenance {
        password
        enabled
      }
      newsletter: sanitySettingNewsletter {
        enabled
        delay
        expiry
      }
      newsletterTemplates: sanitySettingNewsletter {
        templateHomepage
        templateCollection
        templateProduct
      }
      organisation: sanitySettingOrganisation {
        title
        description
        author
        logo {
          asset {
            url
          }
        }
        addressLocality
        addressRegion
        postalCode
        streetAddress
        separator
        url
      }
      scripts: sanitySettingScripts {
        trackingHeader {
          code
        }
        trackingBody {
          code
        }
        trackingFooter {
          code
        }
      }
      social: sanitySettingSocial {
        channels {
          title
          url
        }
      }
      swatches: allSanitySettingSwatches {
        edges {
          node {
            id: _id
            name
            slug {
              current
            }
            linked
            colour {
              hex
            }
            image {
              asset {
                url
              }
            }
          }
        }
      }
      categories: allSanityArticleCategory {
        edges {
          node {
            title
            slug {
              current
            }
            _id
          }
        }
      }
      tracking: sanitySettingTracking {
        googleTagManagerId
        googleSiteVerify
        facebookDomainVerify
        facebookAppId
      }
    }
  `)
