import { getGatsbyImageData } from "gatsby-source-sanity"

import { useApp } from "./useApp"
import { useCore } from "./useCore"

export const useImage = () => {
  const {
    config: {
      services: { sanity },
    },
  } = useApp()
  const {
    helpers: { handleize },
  } = useCore()

  const filename = ({ alt, asset }) => `${handleize(asset?.originalFilename?.split(`.`)?.[0] || alt)}.${asset?.extension}`

  const withFilename = (fluidImage = {}, name = `image.jpg`, properties = [`src`, `srcWebp`, `srcSet`, `srcSetWebp`]) => ({
    ...fluidImage,
    ...properties?.reduce((o, key) => ({ ...o, [key]: fluidImage[key]?.split(`?`)?.join(`/${name}?`) }), {}),
  })

  const getFluidImage = (image, options = {}) =>
    image?.asset?.id || image?.asset?._id
      ? {
          ...withFilename(getGatsbyImageData(image, options, sanity), filename(image)),
          alt: image?.alt ?? "",
          dimensions: image?.asset?.metadata?.dimensions,
        }
      : image

  const getResponsiveImage = (image, options = null) => ({
    desktop: getFluidImage(image?.desktop, options?.desktop || options || {}),
    desktopVideo: image?.desktopVideo,
    desktopAlt: image?.desktop?.alt,
    mobile: getFluidImage(image?.mobile ?? image?.desktop, options?.mobile || options?.desktop || options || {}),
    mobileVideo: image?.mobileVideo ?? image?.desktopVideo,
    mobileAlt: image?.mobile?.alt ?? image?.desktop?.alt,
  })

  return { getFluidImage, getResponsiveImage }
}
