import React, { memo, useMemo, useState, useEffect } from "react"

import { useCheckout, useCheckoutContext } from "../../hooks/useCheckout"
import { useSettings } from "../../hooks/useSettings"
import { useShopContext } from "../../hooks/useShop"
import { useCurrency } from "../../hooks/useCurrency"

import config from "../../../config.default"

export const withCurrency = Component => memo(({ name = "Currency", layout }: any) => {
    const { currentCurrency, showRedirectPopup, toggleRedirectPopup, updateNewCurrency } = useCurrency()
    const { checkout } = useCheckoutContext()
    const { shop } = useShopContext()
    const { updateCurrency } = useCheckout()
    const { currencies } = useSettings()

    const sanityCurrencies = currencies?.edges?.[0]?.node?.languages || config.app.currencies

    const [showDropown, toggleDropdown] = useState(false)

    const findActiveCurrency = sanityCurrencies.find(ele => checkout.currencyCode == ele.currencyCode )

    // Filter all currencies with those that are enabled in Shopify
    const currenciesToDisplay = sanityCurrencies.filter(currency => {
      return shop?.paymentSettings?.enabledPresentmentCurrencies.includes(currency.currencyCode)
    })

    const checkCurrency = (e) => {
      if(e.redirectUrl){
        toggleRedirectPopup(true)
        updateNewCurrency(e)
      } else {
        updateNewCurrency(e)
        updateCurrency(e.currencyCode)
      }
    }

    Component.displayName = name
    return useMemo(
      () => currenciesToDisplay.length ? (
        <>
        <Component
          currency={findActiveCurrency}
          currencies={currenciesToDisplay}
          updateCurrency={checkCurrency}
          visbility={showDropown}
          toggleDropdown={(e) => toggleDropdown(e)}
          layout={layout}
          />
          </>
      ) : null,
      [shop, checkout, updateCurrency]
    )
  })
