import React from "react"
import { Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import tw, { styled, css } from "twin.macro"

import { useRoutes } from "../../hooks/useRoutes"
import { useCore } from "../../hooks/useCore"
import { ImageVideo } from "../ImageVideo/ImageVideo"
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Heading7,
  Heading8,
  Heading9,
  Heading10,
  Heading11,
  Heading12,
  SubHeading,
  bodyXXL,
  bodyXL,
  bodyLG,
  bodySM,
  bodyTinyAlt,
  bodyTiny,
  bodyExtraTiny,
  bodyExtraExtraTiny,
  bodyExtraExtraExtraTiny,
  caption16,
  caption10,
  caption12,
  caption8,
  Heading1Serif,
  Heading2Serif,
  Heading3Serif,
  Heading4Serif,
  Heading5Serif,
  Heading6Serif,
  Heading7Serif,
  Heading8Serif,
  Heading9Serif,
  SubHeadingSerif,
  SubHeadingSerifAlt,
  bodyXXLSerif,
  bodyXLSerif,
  bodyLGSerif,
  bodySerif,
  bodySMSerif,
  bodyTinySerif,
  bodyExtraTinySerif,
  bodyExtraExtraTinySerif,
  LargeBody,
  Body,
} from "../Styled/Text"
import { StyledOl, StyledUl, StyledLi } from "../Styled/List"
import { Accordion } from "../Accordion/Accordion"
import { Table } from "../Table/Table"
import { RichText } from "./RichText"

const CustomLink = styled.span`
  ${({ fontColor }) =>
  fontColor
    ? css`
          color: ${fontColor};
        `
    : null}
`

const CustomImage = tw.img`mx-auto`
const CustomAlignRightSpan = tw.span`block md:text-right`
const CustomAlignCenterSpan = tw.span`block text-center`
const CustomSerifFontSpan = tw.span`block font-serif`
const SentenceCase = tw.span`normal-case`

const Components = {
  heroHeading1: Heading1,
  heroHeading2: Heading2,
  heroHeading3: Heading3,
  heading1: Heading1,
  heading2: Heading2,
  heading3: Heading3,
  heading4: Heading4,
  heading5: Heading5,
  heading6: Heading6,
  heading7: Heading7,
  heading8: Heading8,
  heading9: Heading9,
  heading10: Heading10,
  heading11: Heading11,
  heading12: Heading12,
  subHeading: SubHeading,
  bodyXXL: bodyXXL,
  bodyXL: bodyXL,
  bodyLG: bodyLG,
  bodySM: bodySM,
  bodyTinyAlt: bodyTinyAlt,
  bodyTiny: bodyTiny,
  bodyExtraTiny: bodyExtraTiny,
  bodyExtraExtraTiny: bodyExtraExtraTiny,
  bodyExtraExtraExtraTiny: bodyExtraExtraExtraTiny,
  caption16: caption16,
  caption12: caption12,
  caption10: caption10,
  caption8: caption8,
  heading1Serif: Heading1Serif,
  heading2Serif: Heading2Serif,
  heading3Serif: Heading3Serif,
  heading4Serif: Heading4Serif,
  heading5Serif: Heading5Serif,
  heading6Serif: Heading6Serif,
  heading7Serif: Heading7Serif,
  heading8Serif: Heading8Serif,
  heading9Serif: Heading9Serif,
  subHeadingSerif: SubHeadingSerif,
  subHeadingAltSerif: SubHeadingSerifAlt,
  largeBody: LargeBody,
  body: Body,
  bodyXXLSerif: bodyXXLSerif,
  bodyXLSerif: bodyXLSerif,
  bodyLGSerif: bodyLGSerif,
  bodySerif: bodySerif,
  bodySMSerif: bodySMSerif,
  bodyTinySerif: bodyTinySerif,
  bodyExtraTinySerif: bodyExtraTinySerif,
  bodyExtraExtraTinySerif: bodyExtraExtraTinySerif,
}

const BlockComponents = props => {
  const { style } = props.node || {}

  const Component = Components[style]

  return Component ? <Component>{props.children}</Component> : BlockContent.defaultSerializers.types.block(props)
}

export const withRichText = Component => ({ name = "RichText", children, spacing, ...props }) => {
  const { linkResolver } = useRoutes()

  const {
    helpers: { handleize },
  } = useCore()

  const serializers = {
    marks: {
      link: ({ children, mark }) => {
        const link = linkResolver(mark.link)
        const { color } = mark

        const external = link?.external || mark?.link?.link
        const url = mark?.link?.link || link?.url

        return (
          <CustomLink
            as={url && (external ? `a` : Link)}
            href={external && url}
            target={external && `_blank`}
            title={link?.title}
            to={!external && url}
            fontColor={color?.hex}
          >
            {children}
          </CustomLink>
        )
      },
      alignCenter: ({ children }) => {
        return <CustomAlignCenterSpan>{children}</CustomAlignCenterSpan>
      },
      alignRight: ({ children }) => {
        return <CustomAlignRightSpan>{children}</CustomAlignRightSpan>
      },
      sentenceCase: ({ children }) => {
        return <SentenceCase>{children}</SentenceCase>
      },
      serifFont: ({ children }) => {
        return <CustomSerifFontSpan>{children}</CustomSerifFontSpan>
      }
    },
    list: ({ children, type }) => {
      return type === `number` ? <StyledOl>{children}</StyledOl> : <StyledUl>{children}</StyledUl>
    },
    listItem: ({ children, node }) => {
      return (
        <StyledLi level={node?.level} listItem={node?.listItem}>
          {children}
        </StyledLi>
      )
    },
    types: {
      code: ({ node }) => <div dangerouslySetInnerHTML={{ __html: node.code }} />,
      imageVideoResponsive: ({ node }) => <ImageVideo imageVideo={node} spacing={spacing} />,
      customImage: ({ node }) => <CustomImage src={node?.asset?.url} alt={node?.alt} />,
      block: BlockComponents,
      customPdf: ({ node }) => {
        const name = `${handleize(node?.asset?.originalFilename?.split(`.`)?.[0])}.${node?.asset?.extension}`
        return (
          <a className={"underline"} href={`${node?.asset?.url}/${name}`} target="_blank" rel="noreferrer">
            {node?.title ?? node?.asset?.originalFilename}
          </a>
        )
      },
      accordion: ({ node }) => {
        const { accordionTitle, accordionContent, accordionContentRichText } = node
        return (
          <Accordion layout={"text"} title={accordionTitle}>
            {accordionContentRichText ? <RichText spacing={"default"}>{accordionContentRichText}</RichText> : accordionContent}
          </Accordion>
        )
      },
      table: ({ node }) => {
        const { table, title } = node
        return <Table chart={table} title={title} />
      }
    },
  }

  Component.displayName = name
  return (
    <Component {...props}>
      <BlockContent blocks={children} serializers={serializers} />
    </Component>
  )
}
