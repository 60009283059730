import React from "react"

import { withLayout } from "./withLayout"
import { Body, LayoutStyles } from "./LayoutStyles"
import { Meta } from "../Meta/Meta"
import { Header } from "../Header/Header"
import { Main } from "../Main/Main"
import { Footer } from "../Footer/Footer"
import { Newsletter } from "../Newsletter/Newsletter"
import { NavigationHeaderMobileSlide } from "../Navigation/Header/Mobile/Slide/NavigationHeaderMobileSlide"
import { Cookies } from "../Cookies/Cookies"
import { LocationPopup } from "../LocationPopup/LocationPopup"
import { GlobalStyles } from "twin.macro"

export const Layout = withLayout(
  ({ data, location, children, routes, activeMenu, mobileNavigationLayout, desktopNavigationFormat, handleCloseMenu, transparentHeader }): JSX.Element => (
    <>
      <NavigationHeaderMobileSlide mobileNavigationLayout={mobileNavigationLayout} />
      <Header mobileNavigationLayout={mobileNavigationLayout} desktopNavigationFormat={desktopNavigationFormat} location={location} transparentHeader={transparentHeader} />
      <Body>
        <Meta data={data} url={location?.pathname} routes={routes} />
        <GlobalStyles />
        <LayoutStyles />
        <Main>{children}</Main>
        <Footer />
        <Newsletter path={location?.pathname} />
      </Body>
      <Cookies />
      <LocationPopup />
    </>
  )
)
