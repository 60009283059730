import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useRoutes } from "../../../hooks/useRoutes"

export const withCartEmpty = Component => ({ name = "CartEmpty" }) => {
  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_EMPTY {
      cart: sanityPageCart {
        continueShopping: _rawContinueShopping(resolveReferences: { maxDepth: 2 })
        emptyCartMessage
        emptyDrawerMessage
      }
    }
  `)

  const { continueShopping, emptyCartMessage, emptyDrawerMessage } = cart || {}

  const { linkResolver } = useRoutes()

  const continueLink = linkResolver(continueShopping)

  Component.displayName = name
  return <Component continueLink={continueLink} emptyCartMessage={emptyCartMessage} emptyDrawerMessage={emptyDrawerMessage} />
}
