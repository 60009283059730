import React, { useState, useReducer, createContext } from "react"
import { useCore } from "../hooks/useCore"

export const AppContext: any = createContext({})

export const AppProvider = ({ children, config: siteConfig }): JSX.Element => {
  const [config] = useState(siteConfig || {})
  const [activeVariant, setActiveVariant] = useState(false)
  const [activeProduct, setActiveProduct] = useState(false)
  const [activeCollection, setActiveCollection] = useState(false)
  const [activeSubscribe, setActiveSubscribe] = useState(false)
  const [gridView, setGridView] = useState(true)

  const {
    helpers: { storage },
  } = useCore()

  const values = {
    config,
    activeVariant,
    setActiveVariant,
    activeProduct,
    setActiveProduct,
    activeCollection,
    setActiveCollection,
    activeSubscribe,
    setActiveSubscribe,
    gridView,
    setGridView,
  }

  const initialState = {
    activeCart: false,
    activeSearch: false,
    activeMenu: false,
    searchTerms: "",
    sortBy: null,
    resultsStats: {
      currentPage: null,
      pageSize: null,
      numberOfResults: null,
    },
    searchResults: null,
    newAddressId: "",
    popupActive: false,
    activeCollectionCardView: storage.get(siteConfig?.settings?.keys?.collectionCardView) || siteConfig?.settings?.constraints?.MALE_VIEW,
    gridView: false,
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "TOGGLE_CART":
        return { ...state, activeCart: action.payload, activeSearch: false, activeMenu: false }
      case "SHOW_CART":
        return { ...state, activeCart: true, activeSearch: false, activeMenu: false }
      case "HIDE_CART":
        return { ...state, activeCart: false }
      case "SHOW_SEARCH":
        return { ...state, activeSearch: true, activeCart: false }
      case "HIDE_SEARCH":
        return { ...state, activeSearch: false }
      case "TOGGLE_SEARCH":
        return { ...state, activeSearch: action.payload, activeCart: false, activeMenu: false }
      case "TOGGLE_MENU":
        return { ...state, activeMenu: action.payload, activeSearch: false, activeCart: false }
      case "CLOSE_MENU":
        return { ...state, activeMenu: false }
      case "SET_SEARCH_TERMS":
        return { ...state, searchTerms: action.payload }
      case "SET_SORT_BY":
        return { ...state, sortBy: action.payload }
      case "SET_RESULTS_STATS":
        return { ...state, resultsStats: { ...action.payload } }
      case "SET_SEARCH_RESULTS":
        return { ...state, searchResults: action.payload }
      case "ROUTE_CHANGE":
        return { ...state, activeCart: false, activeSearch: false, activeMenu: false }
      case "SET_NEW_ADDRESS_ID":
        return { ...state, newAddressId: action.payload }
      case "TOGGLE_POPUP":
        return { ...state, activePopup: action.payload }
      case "CHANGE_COLLECTION_CARD_VIEW":
        return { ...state, activeCollectionCardView: action.payload }
    }
  }

  return <AppContext.Provider value={{ ...values, globalStateReducer: useReducer(reducer, initialState) }}>{children}</AppContext.Provider>
}

export const withApp = Component => (props): JSX.Element => (
  <AppContext.Consumer>{values => <Component {...props} layout={values} />}</AppContext.Consumer>
)
