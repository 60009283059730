import React from "react"

import { withInstant } from "./withInstant"
import { Close, Header, Inner, Outer } from "./InstantStyles"
import { Icon } from "../../Icon/Icon";
import { Brand } from "../../Header/Brand/Brand";
import { ReactifySearchProvider, Sensors } from "@usereactify/search"
import config from "../../../../config.default"
import { InstantInner } from "./InstantInner"

export const Instant = withInstant(
  ({ activeSearch, handleClose }): JSX.Element => {
    return activeSearch ? (
      <Outer active={activeSearch} className={`instant-search`}>
        <Header>
          <Close title={"close menu"} onClick={handleClose}>
            <Icon name={"cross"} />
          </Close>
          <Brand color={"yellow"} layout="mobileNav" />
        </Header>
        <Inner>
          <ReactifySearchProvider
            mode="instant-search"
            shopifyPermanentDomain={config?.stores[config?.services?.shopify?.defaultShopName]?.shopifyShopDomain}
          >
            <Sensors />
            <InstantInner />
          </ReactifySearchProvider>
        </Inner>
      </Outer>
    ) : null
  }
)
