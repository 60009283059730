import tw, { styled } from "twin.macro"

interface WrapperProps {
  layout: "card" | "product" | "cart";
  colour?: string;
  forceColour?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  ${tw`text-12`}
  ${({ layout }) => {
    if (layout === "card") return tw`mt-1`
    if (layout === "product" || layout === "cart") return tw`flex-1 mb-2-4`
  }}
  colour: ${({ colour, layout, forceColour }) =>
    forceColour ? forceColour :
      layout === "card" ? "white" :
        layout === "cart" ? "black" :
          layout === "product" ? (colour || "black") :
            "black"}
`