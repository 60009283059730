import React from "react"

import { withCurrency } from "./withCurrency"
import { Icon } from "../Icon/Icon"
import { Currencies, CurrencyButtons, CurrencyButton, ArrowDown, MobileWrapper, CloseButton, Radio } from "./CurrencyStyles"
import {Body} from "../Styled/Text"

export const Currency = withCurrency(
  ({ currency, currencies = [], updateCurrency, visbility, toggleDropdown, layout }): JSX.Element => (
    <Currencies onMouseLeave={() => toggleDropdown(false)} layout={layout}>
      <CurrencyButton current onMouseEnter={() => layout == "desktop" && toggleDropdown(true)} onClick={() => toggleDropdown(!visbility)}layout={"toggle-button"}>
        {layout == "desktop" ? <Body>{currency?.currencyCode} {currency?.symbol}</Body> : <Body>Currency</Body>}
        <ArrowDown name={'arrowAccordionDown'} height="8" show={visbility} />
      </CurrencyButton>
      {layout == "desktop" && (
      <CurrencyButtons visible={visbility ? 'visible' : 'hidden'}>
        {(currencies && currency) &&
        currencies?.map(item => (
          <CurrencyButton key={item?.currencyCode.toString()} onClick={() => updateCurrency(item)} active={item.currencyCode === currency.currencyCode} layout={"list-item"}>
            <Body>{`${item.currencyCode} ${item.symbol}`}</Body>
          </CurrencyButton>
        ))}
      </CurrencyButtons>
      )}
      {layout == "mobile" && (
        <MobileWrapper visible={visbility ? 'visible' : 'hidden'}>
          <CloseButton onClick={() => toggleDropdown(false)}>
            Close <Icon name={"cross"} />
          </CloseButton>
          <CurrencyButtons visible={"visible"}>
            {(currencies && currency) &&
              currencies?.map(item => (
                <CurrencyButton key={item?.currencyCode.toString()} onClick={() => updateCurrency(item)} active={item.currencyCode === currency.currencyCode} layout={"list-item"}>
                  <Body>{`${item.currencyCode} ${item.symbol}`}</Body>
                  <Radio active={item.currencyCode === currency.currencyCode} />
                </CurrencyButton>
              ))}
          </CurrencyButtons>
        </MobileWrapper>
      )}
    </Currencies>
  )
)
