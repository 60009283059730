import { graphql, useStaticQuery } from "gatsby"
import React, { useCallback, useState } from "react"

import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"

export const withCookies = Component => ({ name = "Cookies" }) => {
  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const { cookiesData } = useStaticQuery(graphql`
    query SANITY_SETTINGS_ANNOUNCEMENTS {
      cookiesData: sanitySettingAnnouncement {
        cookies: _rawCookies(resolveReferences: { maxDepth: 4 })
      }
    }
  `)

  const [isCookiesHidden, setIsCookiesHidden] = useState(() => storage.get(keys?.cookies) === "hidden")

  const { cookies } = cookiesData || {}

  const hideCookies = useCallback(() => {
    storage.set(keys?.cookies, "hidden")
    setIsCookiesHidden(true)
  }, [storage, keys?.cookies])

  Component.displayName = name
  return <Component cookies={cookies} handleHide={hideCookies} isCookiesHidden={isCookiesHidden} />
}
