import React from "react"
import { Helmet } from "react-helmet"

import { withMeta } from "./withMeta"

export const Meta = withMeta(
  ({ languages, metadata, metatags, schemas, facebookDomainVerify, tracking }): JSX.Element => (
    <>
      <Helmet htmlAttributes={{ lang: metadata?.lang || "en" }} title={metadata?.title} meta={metatags}>
        <link href={metadata?.siteURL} rel="home" />
        <link href={metadata?.canonical} rel="canonical" />
        {languages?.map(({ key, href, hrefLang, rel }, index) => (
          <link key={key?.toString() + index} href={href} hrefLang={hrefLang} rel={rel} />
          ))}
        {schemas.map((schema, index) => (
          <script type="application/ld+json" key={index}>
            {JSON.stringify(schema)}
          </script>
        ))}
        {facebookDomainVerify?.length > 0 && <meta name="facebook-domain-verification" content={facebookDomainVerify} />}
        <script defer src="https://www.gstatic.com/firebasejs/8.2.3/firebase-app.js"></script>
        <script defer src="https://www.gstatic.com/firebasejs/8.2.3/firebase-auth.js"></script>
        <script defer src="https://www.gstatic.com/firebasejs/8.2.3/firebase-firestore.js"></script>
        <script async src="https://api.seeda.io/js/js?id=drizabone"></script>
      </Helmet>
      {tracking?.map(script => script)}
    </>
  )
)
