import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"

export const CheckoutContext: any = React.createContext({})

export const CheckoutProvider = ({ children }): JSX.Element => {
  const [checkout, setCheckout] = useState(false)

  const [initializedLoading, setInitializedLoading] = useState(true)

  const { template } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_GIFT_CARD_CHECKOUT {
      template: sanityTemplateProduct {
        settingGiftCardProduct {
          shopify {
            handle: shopifyHandle
          }
        }
      }
    }
  `)

  const { settingGiftCardProduct } = template || {}

  const values = {
    checkout,
    setCheckout,
    initializedLoading,
    setInitializedLoading,
    count: checkout?.lineItems?.reduce(
      (acc, cur) => acc + (cur?.variant?.product?.handle === settingGiftCardProduct?.shopify?.handle ? 1 : cur.quantity),
      0
    ),
  }

  return <CheckoutContext.Provider value={{ ...values }}>{children}</CheckoutContext.Provider>
}
