import React from "react"

import { withFooterCopyright } from "./withFooterCopyright"
import { Wrapper, Text } from "./FooterCopyrightStyles"

export const FooterCopyright = withFooterCopyright(({ copy, layout = "normal" }) => (
  <Wrapper layout={layout}>
    <Text>{copy}</Text>
  </Wrapper>
))
