import tw, { styled } from "twin.macro"

export const Wrapper = styled.div<SpacingProps>`
  ${tw`w-full h-full block`}
  ${({ spacing }) => (spacing === "small" ? tw`mt-0 md:mt-1 md:py-1` : spacing === "true" ? tw`py-2-4` : null)}
`

export const Desktop = styled.div`
  ${tw`hidden h-full md:block`}
  ${({ fullHeight }) => fullHeight && tw`h-full`}
`

export const Mobile = tw.div`
  block md:hidden
`

type SpacingProps = {
  spacing: "true" | "false" | string
}
