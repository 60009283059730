import { useApp } from "./useApp"

export const useMessage = tags => {
  const {
    config: {
      settings: {
        tags: { message: messageTagPrefix },
      },
    },
  } = useApp()

  const messageTag = tags?.find(tag => tag.includes(messageTagPrefix)) ?? ""
  const [, first, second] = messageTag.split(":")

  const colour = second ? first : undefined
  const message = second ?? first ?? undefined

  return {
    message,
    colour,
  }
}
