import React, { useState, useEffect, useRef, useCallback, useLayoutEffect, useMemo } from "react"
import { useLocation, globalHistory } from "@reach/router"
import { debounce } from "@material-ui/core"
import { useSettings } from "../../hooks/useSettings";

export const withHeader = Component => ({ name = "Header", mobileNavigationLayout, desktopNavigationFormat, location, transparentHeader }: any) => {
  Component.displayName = name
  const currentRoute = useLocation();
  const { announcementsData } = useSettings()
  const { enableAnnouncementBar } = announcementsData || {}

  const [announcementHeight, setAnnouncementHeight] = useState(0)
  const refAnnouncementItems = useRef([])

  const shouldToggleTransparancy = useMemo(() => {
    return currentRoute?.pathname === "/" || transparentHeader === true
  }, [currentRoute, transparentHeader])


  const [isHeaderScrolling, updateHeaderScrolling] = useState(false)
  const [isHeaderSolid, updateHeaderSolid] = useState(!shouldToggleTransparancy)
  const [isTransparentHeader, updateTransparentHeader] = useState(transparentHeader)

  
  const updateAnnouncementHeight = () => {
    let maxHeight = 0

    if (enableAnnouncementBar) {
      for (const item of refAnnouncementItems.current) {
        if (item) {
          maxHeight = Math.max(item.clientHeight, maxHeight)
        }
      }
      maxHeight += 20 // Padding
    }

    if (maxHeight !== announcementHeight) {
      setAnnouncementHeight(maxHeight)
    }
  }

  const updateAnnouncementHeightDebounced = useCallback(debounce(updateAnnouncementHeight, 200), [])

  useLayoutEffect(() => {
    const handleRedraw = () => {
      updateAnnouncementHeightDebounced()
    }


    updateAnnouncementHeight()
    handleRedraw()
    document.documentElement.style.setProperty('--announcement-height', announcementHeight + "px");

    window.addEventListener('scroll', (e) => {
      const scrollPos = window.pageYOffset

      if (scrollPos >= 30) {
        updateHeaderScrolling(true)
        if(shouldToggleTransparancy){
          updateHeaderSolid(true)
        }
      } else {
        console.log("scrolling shouldn't be solid")
        updateHeaderScrolling(false)
        if(shouldToggleTransparancy){
          updateHeaderSolid(false)
        }
      }
    })
    /**
     * Listen for route change using @reach/router globalHistory
     * - We reset the scrolling state
     * - If the route is *not* on the home page, we apply the solid state
     */
    globalHistory.listen(({action}) => {
      if(action === 'PUSH'){
        // updateHeaderScrolling(false)
        if(!shouldToggleTransparancy){
          updateHeaderSolid(true)
        } else {
          updateHeaderSolid(false)
        }
      }
    })
  }, []);

  const updateHeaderState = (state: boolean) => {
    if(shouldToggleTransparancy){
      updateHeaderSolid(state)
    }
  }

  useEffect(() => {
    if(isTransparentHeader){
      updateHeaderSolid(false)
    }
  }, [isTransparentHeader])

  useEffect(() => {
    if(!shouldToggleTransparancy){
      updateHeaderSolid(true)
      updateTransparentHeader(false)
    } else {
      updateHeaderSolid(false)
      updateTransparentHeader(true)
    }
  }, [currentRoute, shouldToggleTransparancy])

  const handleMouseEnter = useCallback(() => {
    updateHeaderState(true)
    updateTransparentHeader(false)
  }, [updateHeaderState])

  const handleMouseLeave = useCallback(() => {
    updateHeaderState(false)
    updateTransparentHeader(transparentHeader)
  }, [transparentHeader, updateHeaderState])


  return (
    <Component
      announcementItemRefs={refAnnouncementItems}
      announcementHeight={announcementHeight}
      currentRoute={currentRoute}
      isScrolling={isHeaderScrolling}
      isSolid={isHeaderSolid}
      updateHeaderSolid={updateHeaderState}
      mobileNavigationLayout={mobileNavigationLayout}
      desktopNavigationFormat={desktopNavigationFormat}
      location={location}
      enableAnnouncementBar={enableAnnouncementBar}
      isTransparentHeader={isTransparentHeader}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      transparentHeader={transparentHeader}
      shouldToggleTransparancy={shouldToggleTransparancy}
    />
  )
}
