import tw, { styled } from "twin.macro"


export const PrevArrow = styled.button`
  ${tw`absolute top-1/2 left-1 z-20 translate-y-[-50%]`}
  &.swiper-button-disabled {
    ${tw`opacity-50 text-grey`}
  }
`

export const NextArrow = styled.button`
  ${tw`absolute top-1/2 right-1 z-20 translate-y-[-50%]`}
  &.swiper-button-disabled {
    ${tw`opacity-50 text-grey`}
  }
`