import React from "react"

import { useImage } from "../../hooks/useImage"
import { useRoutes } from "../../hooks/useRoutes"

export const withImageVideo =
  Component =>
  ({
    name = "ImageVideo",
    imageVideo = {},
    ratio = "",
    spacing = "false",
    externalPlaying = null,
    setExternalPlaying = null,
    loop = false,
    fullHeight = false,
  }) => {
    const { getResponsiveImage } = useImage()

    const { linkResolver } = useRoutes()

    const { link: originalLink, autoplay, controls } = imageVideo ?? {}

    const link = linkResolver(originalLink)

    const { desktop, desktopAlt, desktopVideo, mobile, mobileAlt, mobileVideo } = getResponsiveImage(imageVideo, {
      desktop: { maxWidth: 1200, layout: "fullWidth" },
      mobile: { maxWidth: 800, layout: "fullWidth" },
    })

    const desktopAspectRatio = imageVideo?.desktopAspectRatio
    const mobileAspectRatio = imageVideo?.mobileAspectRatio

    Component.displayName = name
    return (
      <Component
        desktop={desktop}
        desktopAlt={desktopAlt}
        desktopVideo={desktopVideo}
        mobile={mobile}
        mobileAlt={mobileAlt}
        mobileVideo={mobileVideo}
        ratio={ratio}
        spacing={spacing}
        externalPlaying={externalPlaying}
        setExternalPlaying={setExternalPlaying}
        loop={loop}
        link={link}
        autoplay={autoplay}
        controls={controls}
        fullHeight={fullHeight}
        desktopAspectRatio={desktopAspectRatio}
        mobileAspectRatio={mobileAspectRatio}
      />
    )
  }
