import React from "react"
import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"

import { useNewsletter } from "../../hooks/useNewsletter"

export const withNewsletter = Component => ({ name = "Newsletter", path }) => {
  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const { activeSubscribe, hideSubscribe } = useNewsletter(path)
  const isCookiesHidden = storage.get(keys?.cookies) === "hidden"
  Component.displayName = name
  return <Component isCookiesHidden={isCookiesHidden} activeSubscribe={activeSubscribe} setActiveSubscribe={hideSubscribe} />
}
