import React from "react"

import { withCartWidget } from "./withCartWidget"
import { Wrapper, CartButton, IconWrapper, Count, StyledIcon } from "./CartWidgetStyles"

export const CartWidget = withCartWidget(
  ({ count, handleActiveCart }): JSX.Element => (
    <Wrapper>
      <CartButton onClick={handleActiveCart}>
        <IconWrapper>
          <StyledIcon name={"bag"} />
          {count > 0 ? <Count>{count}</Count> : null}
        </IconWrapper>
      </CartButton>
    </Wrapper>
  )
)
