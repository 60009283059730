import { useFirebase } from "./useFirebase"

export const useSale = () => {
  const { sale } = useFirebase()

  const getSaleVisibility = (promotion = [], promotionVisibility: "hide" | "show" = "show"): "hide" | "show" => {
    const sectionPromotions = promotion?.reduce((result, { slug }) => (slug?.current ? [...result, slug.current] : result), []) || []
    const isPromotionActive = sale?.enabled && typeof sale.name === "string" && sectionPromotions.includes(sale.name.toLowerCase())
    return isPromotionActive && promotionVisibility ? promotionVisibility : "show"
  }

  const getOnSale = () => {
    if (sale?.enabled && sale?.name) {
      return sale?.name
    }

    return null
  }

  return { getSaleVisibility, getOnSale }
}
