import React, { useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../../../hooks/useApp"
import { useShopify } from "../../../../../hooks/useShopify"
import { useSale } from "../../../../../hooks/useSale"
import { useNavigation } from "../../../../../hooks/useNavigation"

export const withNavigationHeaderMobileList = Component => ({ name = "NavigationHeaderMobileList", handleCloseMenu }) => {
  const { mapItems } = useNavigation()
  const { getOnSale } = useSale()
  const { edgeNormaliser } = useShopify()
  const { globalStateReducer } = useApp()

  const [activeDropdown, updateActiveDropdown] = useState("")

  const { menus, promotions: rawPromotions } = useStaticQuery(graphql`
    query SANITY_SETTINGS_MOBILE_MAIN_MENU {
      menus: sanitySettingMenus {
        mobileHeaderMain {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
        }
      }
      promotions: allSanityPromotion {
        edges {
          node {
            title
            slug {
              current
            }
            promotion {
              desktopHeader: _rawDesktopHeader(resolveReferences: { maxDepth: 10 })
              mobileHeader: _rawMobileHeader(resolveReferences: { maxDepth: 10 })
              homepage: _rawHomepage(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    }
  `)

  const { mobileHeaderMain } = menus || {}
  const [{ activeSearch }, dispatch] = globalStateReducer

  const promotions = rawPromotions
    ? edgeNormaliser(rawPromotions).reduce(
        (result, item) =>
          item?.slug?.current
            ? {
                ...result,
                [item.slug.current]: { items: item?.promotion?.mobileHeader?.items },
              }
            : result,
        {}
      )
    : {}
  const saleName = getOnSale()

  const rawItems = saleName && promotions[saleName]?.items ? promotions[saleName]?.items : mobileHeaderMain?.items
  const items = useMemo(() => mapItems(rawItems), [rawItems, mapItems])

  const closeMenu = () => {
    updateActiveDropdown("")
    handleCloseMenu()
  }

  const openSearch = () => {
    dispatch({
      type: "TOGGLE_SEARCH",
      payload: !activeSearch,
    })
  }

  Component.displayName = name
  return <Component items={items} activeDropdown={activeDropdown} updateActiveDropdown={updateActiveDropdown} handleCloseMenu={closeMenu} openSearch={openSearch} />
}
