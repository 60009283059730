import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCustomerAccessToken, useCustomerContext } from "../../../hooks/useCustomer"

export const withAccountWidget = Component => ({ name = "AccountWidget", layout = "desktop" }: any) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { global } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_ACCOUNT {
      global: sanityTemplateGlobal {
        additionalAccountText
      }
    }
  `)

  const { additionalAccountText } = global || {}

  const { customer } = useCustomerContext()

  const { getCustomer } = useCustomerAccessToken()

  if (!customer) getCustomer()

  const accountLink = routes?.DASHBOARD
  const loginLink = routes?.LOGIN

  const url = customer ? accountLink : loginLink

  Component.displayName = name
  return <Component url={url} additionalAccountText={additionalAccountText} layout={layout} />
}
