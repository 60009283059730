import React from "react"
import { ButtonWrapper, NoResults } from "./InstantStyles"
import { SearchForm } from "../Form/SearchForm"
import { Results } from "@usereactify/search"
import { Loading } from "../List/Loading/Loading"
import { SearchListError } from "../List/SearchListError"
import { RichText } from "../../RichText/RichText"
import { StyledButton } from "../../Styled/Button"
import { withInstantInner } from "./withInstantInner"
import { SearchResultsCard } from "../Results/SearchResultsCard"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { useLivePrices } from "../../../hooks/useLivePrices"

export const InstantInner = withInstantInner(
  ({ handleClose, searchTerm, submitSearchTerm, additionalViewAllResultsText, noResults }): JSX.Element => {
    const { prices } = useLivePrices()
    const { windowSize } = useWindowSize()
    const isMobile = windowSize.width <= 768

    return (
      <>
        <SearchForm handleClose={handleClose} />
        <Results
          includeFields={["*"]}
          pageSize={6}
          listClassName="instant-search-results"
          renderResultCardProduct={({ product, pagePosition }) => (searchTerm?.length && (!isMobile || pagePosition <= 4) ? <SearchResultsCard item={product} prices={prices} /> : null)}
          renderResultCardCallout={props => <></>}
          renderLoading={() => <Loading size={12} notEmpty={false} />}
          renderNoResults={() => (
            <NoResults>
              <RichText>{noResults}</RichText>
            </NoResults>
          )}
          renderError={error => <SearchListError error={error} />}
        />
        {searchTerm?.length ? (
          <ButtonWrapper>
            <StyledButton size={"topBottom"} colour={"transparentBlack"} onClick={event => {
              event.preventDefault()
              submitSearchTerm(searchTerm)
            }}>
              {additionalViewAllResultsText}
            </StyledButton>
          </ButtonWrapper>
        ) : null}
      </>
    )
  }
)
