import tw, { styled } from "twin.macro"

export const Wrapper = tw.nav`
  flex flex-row lg:pt-4 gap-2 sm:gap-0 flex-[2] sm:flex-auto
`

export const List = tw.div`
  block w-4/12 md:w-1/2
`

export const ListItem = tw.div`
  text-12 py-0-5 mb-0-1
`

export const Title = styled.div`
  ${tw`flex flex-row items-center text-black pb-1-2 md:pb-0-5 md:mb-0-6 text-12`}
`
