import React from "react"

import { withIcon } from "./withIcon"
import { StyledIcon } from "../Styled/Icon"

export const Icon = withIcon(({ icon, title, width, height, className, handleClick }) => (
  <StyledIcon title={title} className={className} onClick={handleClick}>
    <svg viewBox={icon.viewBox} id={icon.id} width={width} height={height}>
      <title id={`${icon.id}Title`}>{title}</title>
      <use xlinkHref={icon.url} />
    </svg>
  </StyledIcon>
))
