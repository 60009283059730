import tw, { styled } from "twin.macro"

export const Wrapper = tw.span`
  flex flex-row items-center
`

export const PriceTag = styled.p<PriceTagProps>`
  ${({ layout }) => {
    if (layout === "product") return tw`text-16`
    if (layout === "card" || layout === "other") return tw`text-12`
    if (layout === "cart") return tw`text-12 md:text-16`
    if (layout === "drawer") return tw`text-12 md:text-16`
  }}
  ${({ layout, sale }) => {
    if (layout === "product" && sale === "true") return tw`text-grey-dark`
    if (sale === "true") return tw`text-white`
    return null
  }}
  ${({ strike }) => (strike === "true" ? tw`line-through mr-0-2` : null)}
`

type PriceTagProps = {
  sale?: "true" | "false"
  strike?: "true" | "false"
  layout: "product" | "card"
}
