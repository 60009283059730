import tw, { styled } from "twin.macro"

import { Body } from "../../Styled/Text"
import {Icon} from "../../Icon/Icon";

export const Wrapper = tw.div`
  relative
`

export const CartButton = styled.button`
  ${tw`flex flex-row items-center py-1-2 mlg:py-0-8 hover:underline`}
`

export const StyledIcon = styled(Icon)`
  svg {
    ${tw`w-auto h-auto hd:h-1-4`}
  }
`

export const Text = styled(Body)`
  ${tw`hidden mlg:block`}
`

export const IconWrapper = tw.div`
  relative mlg:mr-0-8
`

export const Count = tw.p`
  block w-1-3 h-1-3 absolute top-0-6 -right-0-6 p-0-1 text-10 border border-grey-dark rounded-full bg-white md:bg-black text-black md:text-white
`
