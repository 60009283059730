import React from "react"
import { useApp } from "../../../hooks/useApp"

export const withMenu = Component => ({ name = "Menu", mobileNavigationLayout }) => {
  const { globalStateReducer } = useApp()

  const [{ activeMenu }, dispatch] = globalStateReducer

  const handleToggleMenu = () => {
    dispatch({
      type: "TOGGLE_MENU",
      payload: !activeMenu,
    })
  }

  Component.displayName = name
  return mobileNavigationLayout === "slide" && activeMenu ? null : (
    <Component active={activeMenu} handleClick={handleToggleMenu} mobileNavigationLayout={mobileNavigationLayout} />
  )
}
