import gql from "graphql-tag"

import { IMAGE_FRAGMENT } from "./imageFragment"
import { PRICE_FRAGMENT } from "./priceFragment"

export const VARIANT_FRAGMENT = gql`
  fragment VariantFragment on ProductVariant {
    id
    sku
    title
    availableForSale
    currentlyNotInStock
    quantityAvailable
    requiresShipping
    selectedOptions {
      name
      value
    }
    image {
      ...ImageFragment
    }
    ...PriceFragment
  }
  ${IMAGE_FRAGMENT}
  ${PRICE_FRAGMENT}
`

export const VARIANT_FRAGMENT_LITE = gql`
  fragment VariantFragmentLite on ProductVariant {
    id
    sku
    title
    availableForSale
    currentlyNotInStock
    quantityAvailable
    selectedOptions {
      name
      value
    }
    ...PriceFragment
  }
  ${PRICE_FRAGMENT}
`
