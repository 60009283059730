import React from "react"

import { Description, Heading, Column, Row, Scroll, Table as Wrapper } from "./TableStyles"

export const Table = ({ chart, title, description, colour }) =>
  chart ? (
    <Scroll colour={colour}>
      <Wrapper colour={colour}>
        {title && <Heading>{title}</Heading>}
        {chart?.rows?.map((row, index) => (
          <Row key={`${row?.key}_${index}`} colour="white">
            {row?.cells?.map((column, index) => (
              <Column colour={colour} key={`${column}_${index}`}>{column || <div dangerouslySetInnerHTML={{ __html: `&nbsp;` }} />}</Column>
            ))}
          </Row>
        ))}
      </Wrapper>
      {description && <Description colour={colour} spacing={'sizeGuide'}>{description}</Description>}
    </Scroll>
  ) : null
