import tw, { css, styled } from "twin.macro"

const layouts = {
  desktop: tw`hidden lg:block`,
  mobile: tw`block lg:hidden`,
  mobileNav: tw`ml-auto`,
}

export const Wrapper = styled.div`
  ${({ color }) => (color === "white" ? tw`text-white` : tw`text-black invert`)}
  ${({ layout }) => layout && layouts[layout]}
    svg {
    ${tw`transition-all duration-300`}
  }
`
