import tw, { styled } from "twin.macro"

const styles = {
  default: tw`block max-w-full select-none`,
}

export const StyledIcon = styled.span`
  ${({ onClick }) => (typeof onClick === "function" ? tw`cursor-pointer` : null)}
  ${styles.default}
  > svg {
    fill: transparent;
  }
`
