import tw, { styled } from "twin.macro"

export const Outer = tw.div`
  relative
`

export const Inner = styled.div`
  ${tw`absolute inset-0 h-full w-full opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto`}
  ${({ showBorder }) => (showBorder ? tw`border border-transparent group-hover:border-white` : tw``)}
`
