import React from "react"

import { withCartItemPrice } from "./withCartItemPrice"
import { Wrapper, PriceTag, Message } from "./CartItemPriceStyles"

export const CartItemPrice = withCartItemPrice(({ isOnSale, price, compareAtPrice, discountMessage }) => (
  <div>
    <Wrapper>
      {isOnSale ? (
        <>
          <PriceTag strike>{compareAtPrice}</PriceTag>
          &nbsp;
        </>
      ) : null}
      <PriceTag sale={isOnSale}>{price}</PriceTag>
    </Wrapper>
    {discountMessage && <Message> {discountMessage}</Message>}
  </div>
))
