import { useState, useEffect } from "react"

export const useMedia = () => {
  const [screenSize, setScreenSize] = useState(getScreenSize)

  function getScreenSize() {
    const windowWidth = window.innerWidth
    switch (true) {
      case windowWidth < 768:
        return "small"
      case windowWidth < 1024:
        return "medium"
      default:
        return "large"
    }
  }

  useEffect(() => {
    function handleResize() {
      setScreenSize(getScreenSize())
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const isSmall = screenSize === "small"
  const isMedium = screenSize === "medium"
  const isLarge = screenSize === "large"

  return { isSmall, isMedium, isLarge }
}