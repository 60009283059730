import React from "react"
import { Link } from "gatsby"

import { withNavigationFooter } from "./withNavigationFooter"
import { SocialMedia } from "../../SocialMedia/SocialMedia"
import { Wrapper, List, ListItem, Title } from "./NavigationFooterStyles"

export const NavigationFooter = withNavigationFooter(
  ({ items }): JSX.Element => (
    <Wrapper>
      {items?.map((item, index) => (
        <List key={index}>
          {item?.title && <Title>{item?.title}</Title>}
          {item?.items?.map(listItem => (
            <ListItem key={listItem?.title}>
              <Link to={listItem?.url}>{listItem?.title}</Link>
            </ListItem>
          ))}
        </List>
      ))}
      <SocialMedia layout={"mobile"} />
    </Wrapper>
  )
)
