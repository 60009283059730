import tw, { styled } from "twin.macro"

export const Outer = styled.section<ActiveProps>`
  ${tw`h-0 overflow-hidden transition-all duration-300 z-50 bg-black`}
  ${({ active }) => (active === "true" ? tw`mx-2-4 md:mx-0 border-b md:border-0` : tw`border-0`)}
`

export const Inner = tw.div`
  w-full h-full relative flex items-center
`

export const Item = styled.div<ActiveProps>`
  ${tw`absolute inset-0 h-full flex items-center justify-center text-center transform transition-all duration-300 text-10 md:text-12 font-bold text-white uppercase tracking-widest px-0-8`}
  ${({ active }) => (active === "true" ? tw`opacity-100 visible translate-y-0` : tw`opacity-0 invisible -translate-y-6`)}
`

export const Content = tw.p`
  block
`

type ActiveProps = {
  active: "true" | "false"
}
