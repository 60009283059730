import React from "react"

import { withLocationPopup } from "./withLocationPopup"
import { Wrapper, RichText } from "./DescriptionStyles"
import { Body } from "../Styled/Text"
import { UnderlinedButton, StyledButton } from "../Styled/Button"
import {
  ModalExternal,
  ModalContentsWithLayout,
} from "../Modal/Modal"

export const LocationPopup = withLocationPopup(({ show, toggleLocationPopup, currency, current }) => (show && current) ? (
  <ModalExternal isOpen={show} setIsOpen={toggleLocationPopup} layout={'location'}>
    <ModalContentsWithLayout layout={"locationConfirmation"}>
      <Body align={'center'} spacing={'default'}>
        <p>You are currently shopping on the {current?.name} store.</p>
        <p>Are you sure you want to go to the {currency?.name} store?</p>
      </Body>
      <StyledButton onClick={() => window.location.href = currency.redirectUrl} wide uppercase colour={'blackWhite'}>Continue to {currency?.name}</StyledButton>
      <Body align={'center'} spacing={'location'}>or</Body>
      <StyledButton wide uppercase colour={'blackWhite'} onClick={() => toggleLocationPopup(false)}>Stay on {current?.name}</StyledButton>
    </ModalContentsWithLayout>
  </ModalExternal>
) : null)
