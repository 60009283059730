import React, { Fragment } from "react"

import { withNavigationHeaderMobileList } from "./withNavigationHeaderMobileList"
import { List, Item, Button, SubList, SubItem, SubHeading, Footer, Header, Close, Back, SearchButton, StyledLink } from "./NavigationHeaderMobileListStyles"
import { AccountWidget } from "../../../../Account/Widget/AccountWidget"
import {Icon} from "../../../../Icon/Icon";
import {Brand} from "../../../../Header/Brand/Brand";
import {Currency} from "../../../../Currency/Currency";

export const NavigationHeaderMobileList = withNavigationHeaderMobileList(({ items, activeDropdown, updateActiveDropdown, handleCloseMenu, openSearch }) => (
  <>
    <Header>
      {activeDropdown && <Back onClick={() => updateActiveDropdown("")}><Icon name={"back"} /></Back>}
      <Close title={"close menu"} onClick={handleCloseMenu} active={activeDropdown}>
        <Icon name={"cross"} />
      </Close>
      <Brand color={"yellow"} layout="mobileNav" />
    </Header>
    <List>
      <SearchButton onClick={() => openSearch()}>
        <Icon name={"search"} />
        Type to search
      </SearchButton>
      {items?.map(item => (
        <>
          {item?.items?.length > 0 && item?.url ? (
            <Item>
              <StyledLink to={item.url}>{item.title}</StyledLink>
              <Button full={false} onClick={() => updateActiveDropdown(item?.title)}>{item?.items?.length > 0 && <Icon name={"chevronRight"} />}</Button>
            </Item>
            ) : item?.items?.length > 0 ? (
            <Item>
              <Button full={true} onClick={() => updateActiveDropdown(item?.title)}>{item.title} {item?.items?.length > 0 && <Icon name={"chevronRight"} />}</Button>
            </Item>
            ) : (
              <Item>
                <StyledLink to={item.url}>{item.title}</StyledLink>
              </Item>
            )
          }
          {item?.items?.length > 0 && (
            <SubList active={activeDropdown === item?.title}>
              <SubHeading>{item.url ? <StyledLink to={item.url}>{item.title}</StyledLink> : item.title}</SubHeading>
              {item?.items?.map(item => (
                <SubItem key={item.title}>{item.url ? <StyledLink to={item.url}>{item.title}</StyledLink> : item.title}</SubItem>
              ))}
            </SubList>
          )}
        </>
      ))}
      <Footer>
        <AccountWidget layout={"mobile"} />
        <Currency layout={"mobile"}/>
      </Footer>
    </List>
  </>
))
