import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

const generateColor = (color) => {
  switch(color) { 
       case 'red': 
            return tw`text-red`
       case 'maroon': 
            return tw`text-maroon`
        case 'yellow':
          return tw`text-yellow`
        case 'olive':
          return tw`text-olive`
        case 'white':
          return tw`text-white`
        case 'black':
          return tw`text-black`
        case 'grey':
          return tw`text-grey-hoverTitle`
     }
 } 
export const StyledNavigationHeaderDesktop = tw.nav`
  hidden mlg:flex flex-row
`

export const List = tw.ul`
  list-none hidden lg:flex flex-row items-center
`

export const ListItem = styled.li<ListItemProps>`
  ${tw`font-semibold text-12 hd:text-14 flex items-center px-1-6 mx-0-8 uppercase lg:h-9`}
  ${({ format }) => (format === "normal" ? tw`border-b border-transparent` : null)}
`
  
  export const StyledLink = styled(Link)`
  ${tw`py-1 border-b-2 border-transparent hover:border-black tracking-widest cursor-pointer`}
  ${({ active }) => (active ? tw`border-black` : null)}
  ${({ isSolid, titleHoverColour }) => (isSolid && titleHoverColour ? generateColor(titleHoverColour) : null)}
`

type ListItemProps = {
  active: boolean
  format: string
  titleHoverColour: string
  isSolid: boolean
}
