import React from "react"

import Account from "../../../static/icons/account.svg"
import Arrow from "../../../static/icons/arrow.svg"
import ArrowDropdown from "../../../static/icons/arrow-dropdown.svg"
import ArrowDropdownActive from "../../../static/icons/arrow-dropdown-active.svg"
import ArrowRight from "../../../static/icons/arrow-right.svg"
import ArrowRightThin from "../../../static/icons/arrow-right-thin.svg"
import ArrowDown from "../../../static/icons/arrow-down.svg"
import ArrowLeft from "../../../static/icons/arrow-left.svg"
import ArrowLeftSmall from "../../../static/icons/arrow-left-small.svg"
import Chevron from "../../../static/icons/chevron.svg"
import ChevronLeft from "../../../static/icons/chevron-left.svg"
import ChevronRight from "../../../static/icons/chevron-right.svg"
import ChevronDown from "../../../static/icons/chevron-down.svg"
import ChevronUp from "../../../static/icons/chevron-up.svg"
import Cross from "../../../static/icons/cross.svg"
import Plus from "../../../static/icons/plus.svg"
import Star from "../../../static/icons/star.svg"
import Tick from "../../../static/icons/tick.svg"
import Video from "../../../static/icons/video.svg"
import Wishlist from "../../../static/icons/wishlist.svg"
import WishlistFilled from "../../../static/icons/wishlist-filled.svg"
import Menu from "../../../static/icons/menu.svg"
import Minus from "../../../static/icons/minus.svg"
import Back from "../../../static/icons/back.svg"
import Remove from "../../../static/icons/remove.svg"
import GridOn from "../../../static/icons/grid-on.svg"
import GridOff from "../../../static/icons/grid-off.svg"
import ColumnOn from "../../../static/icons/column-on.svg"
import ColumnOff from "../../../static/icons/column-off.svg"

import Mail from "../../../static/icons/mail.svg"
import Phone from "../../../static/icons/phone.svg"
import Home from "../../../static/icons/home.svg"
import Search from "../../../static/icons/search.svg"
import User from "../../../static/icons/user.svg"
import Pin from "../../../static/icons/pin.svg"
import Bag from "../../../static/icons/bag.svg"
import Chat from "../../../static/icons/chat.svg"
import Edit from "../../../static/icons/edit.svg"
import Trash from "../../../static/icons/trash.svg"

import Twitter from "../../../static/icons/twitter.svg"
import Youtube from "../../../static/icons/youtube.svg"
import Facebook from "../../../static/icons/facebook.svg"
import LinkedIn from "../../../static/icons/linkedin.svg"
import Pinterest from "../../../static/icons/pinterest.svg"
import Instagram from "../../../static/icons/instagram.svg"
import WhatsApp from "../../../static/icons/whatsapp.svg"
import SocialGoogle from "../../../static/icons/social-google.svg"
import SocialFacebook from "../../../static/icons/social-facebook.svg"
import SKidmanLogo from "../../../static/icons/skidman-logo.svg"

import AfterPay from "../../../static/icons/payment-afterpay.svg"
import American from "../../../static/icons/payment-american.svg"
import ApplePay from "../../../static/icons/payment-applepay.svg"
import MasterCard from "../../../static/icons/payment-mastercard.svg"
import Paypal from "../../../static/icons/payment-paypal.svg"
import Visa from "../../../static/icons/payment-visa.svg"
import Zip from "../../../static/icons/payment-zip.svg"

export const withIcon = Component => ({ componentName = "Icon", width, height, name, title, className, handleClick = null }: any) => {
  const icons = {
    account: Account,
    arrow: Arrow,
    arrowDropdown: ArrowDropdown,
    arrowDropdownActive: ArrowDropdownActive,
    arrowLeft: ArrowLeft,
    arrowAccordionRight: ArrowRight,
    arrowRightThin: ArrowRightThin,
    arrowAccordionDown: ArrowDown,
    arrowLeftSmall: ArrowLeftSmall,
    chevron: Chevron,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    chevronDown: ChevronDown,
    chevronUp: ChevronUp,
    cross: Cross,
    plus: Plus,
    star: Star,
    tick: Tick,
    video: Video,
    remove: Remove,
    wishlist: Wishlist,
    wishlistFilled: WishlistFilled,
    twitter: Twitter,
    youtube: Youtube,
    facebook: Facebook,
    pinterest: Pinterest,
    instagram: Instagram,
    linkedin: LinkedIn,
    whatsapp: WhatsApp,
    menu: Menu,
    minus: Minus,
    back: Back,
    afterPay: AfterPay,
    american: American,
    applePay: ApplePay,
    masterCard: MasterCard,
    paypal: Paypal,
    visa: Visa,
    zip: Zip,
    socialGoogle: SocialGoogle,
    socialFacebook: SocialFacebook,
    skidmanLogo: SKidmanLogo,

    mail: Mail,
    email: Mail,
    phone: Phone,
    home: Home,
    search: Search,
    user: User,
    pin: Pin,
    bag: Bag,
    chat: Chat,
    edit: Edit,
    trash: Trash,
    gridOn: GridOn,
    gridOff: GridOff,
    columnOn: ColumnOn,
    columnOff: ColumnOff,
  }

  const icon = icons[name]

  Component.displayName = componentName
  return icon ? (
    <Component
      icon={icon}
      title={title || name}
      width={width || icon.viewBox.split(" ")[2]}
      height={height || icon.viewBox.split(" ")[3]}
      className={className}
      handleClick={handleClick}
    />
  ) : (
    <p>{name}</p>
  )
}
