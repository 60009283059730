import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useApp } from "../../../hooks/useApp"

export const withBrand = Component => ({ name = "Brand", layout = "desktop", color }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const url = routes.HOMEPAGE

  Component.displayName = name
  return <Component color={color} url={url} logo={logo?.file?.childImageSharp?.fluid} layout={layout} />
}
