import React from "react"

import { withFooterContact } from "./withFooterContact"
import { Wrapper, Option, Title, StyledIcon, StyledLink, Description, Content } from "./FooterContactStyles"

export const FooterContact = withFooterContact(
  ({ titleLink, chatText, chatAvailableText, callText, call, emailText, email, storesLink, stockistLink, handleChatClick, isChatAvailable, footerContactIcons, buttonLink }) => (
    <Wrapper>
      {footerContactIcons?.map((item) => {
        return item?.type === "chat" ? (
          <Option key={item?._key} as={"button"} onClick={handleChatClick}>
            {item?.type && <StyledIcon name={item?.type} width={14} />}
            <Content>
              {item?.title && <Title>{item?.title}</Title>}
              {item?.description && isChatAvailable ? <Description>{item?.description}</Description> : null}
            </Content>
          </Option>
        ) : (
          <Option key={item?._key} as={"a"} href={item?.link}>
            {item?.type && <StyledIcon name={item?.type} width={14} />}
            <Content>
              {item?.title && <Title>{item?.title}</Title>}
              {item?.description && <Description>{item?.description}</Description>}
            </Content>
          </Option>
        )
      })}
    </Wrapper>
  )
)
