import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCheckout, useCheckoutContext } from "../../../../hooks/useCheckout"
import { useShopify } from "../../../../hooks/useShopify"

export const withSummary = Component => ({ name = "Summary" }) => {
  const { checkout } = useCheckoutContext()

  const { removeDiscountCode } = useCheckout()

  const { formatMoney } = useShopify()

  const { cart } = useStaticQuery(graphql`
      query SANITY_PAGE_CART_SUMMARY {
          cart: sanityPageCart {
              freeShippingThreshold
              additionalSubtotalText
              additionalDiscountText
              additionalShippingText
              additionalShippingPlaceholderText
              additionalTotalText
          }
      }
  `)

  const {
    additionalSubtotalText,
    additionalDiscountText,
    additionalShippingText,
    additionalShippingPlaceholderText,
    additionalTotalText,
  } = cart || {}
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: checkout?.currencyCode,
    currencyDisplay:'narrowSymbol'
  });

  const amountDue = formatter.format(checkout?.paymentDue?.amount)

  const subTotal = formatter.format(checkout?.lineItemsSubtotalPrice?.amount)

  const shippingText = checkout?.shippingLine?.price
    ? checkout?.shippingLine?.price !== "0.0"
      ? formatMoney(checkout?.shippingLine?.price)
      : additionalShippingPlaceholderText
    : additionalShippingPlaceholderText

  const discountApplied = React.useMemo(() => {
    const lineItemsSubtotalPrice = parseFloat(checkout?.lineItemsSubtotalPriceV2?.amount || checkout?.lineItemsSubtotalPrice?.amount)
    const subtotalPrice = parseFloat(checkout?.subtotalPriceV2?.amount || checkout?.subtotalPrice?.amount)
    return lineItemsSubtotalPrice - subtotalPrice

  }, [checkout])
  
  const formattedDiscountApplied = React.useMemo(() => {
    return formatter.format(discountApplied)
  }, [discountApplied, formatter])

  const appliedDiscountCode = checkout?.discountApplications?.find(discount => discount.code)?.code

  const handleDiscountCodeRemove = () => removeDiscountCode()

  const currencyCode = checkout?.currencyCode

  Component.displayName = name
  return (
    <Component
      amountDue={amountDue}
      subTotal={subTotal}
      shippingText={shippingText}
      discountApplied={discountApplied}
      formattedDiscountApplied={formattedDiscountApplied}
      appliedDiscountCode={appliedDiscountCode}
      handleDiscountCodeRemove={handleDiscountCodeRemove}
      currencyCode={currencyCode}
      additionalSubtotalText={additionalSubtotalText}
      additionalDiscountText={additionalDiscountText}
      additionalShippingText={additionalShippingText}
      additionalShippingPlaceholderText={additionalShippingPlaceholderText}
      additionalTotalText={additionalTotalText}
    />
  )
}
