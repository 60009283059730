import React from "react"
import { Link } from "gatsby"

import { withCheckout } from "./withCheckout"
import { StyledButton } from "../../../Styled/Button"
import { FreeShippingCallToAction } from "./CheckoutDrawerStyles";

export const CheckoutDrawer = withCheckout(
  ({ cartUrl, loading, handleCheckout, additionalCheckoutButton, additionalCheckoutButtonLoading, additionalViewBagButton, freeShippingAmount, freeShippingCallToAction }): JSX.Element => (
    <>
      <StyledButton onClick={handleCheckout} colour={"yellow"} wide>
        {loading ? additionalCheckoutButtonLoading : additionalCheckoutButton}
      </StyledButton>
      {freeShippingAmount > 0 ? (
        <FreeShippingCallToAction>
          {freeShippingCallToAction}
        </FreeShippingCallToAction>
      ) : null}
    </>
  )
)
