import React from "react"

import { withLoading } from "./withLoading"
import { Placeholder } from "./LoadingStyles"

export const Loading = withLoading(({ placeholders, notEmpty }) =>
  notEmpty
    ? placeholders.map((item, index) => <Placeholder key={`${item}_${index}`} fluid={{ aspectRatio: 2 / 3, src: null, srcSet: null, sizes: null }} />)
    : null
)
