import React, { useState, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useShopify } from "../../../../../hooks/useShopify"
import { useSale } from "../../../../../hooks/useSale"
import { useNavigation } from "../../../../../hooks/useNavigation"

export const withNavigationHeaderDesktopMain = Component => ({ isSolid, name = "NavigationHeaderDesktopMain", desktopNavigationFormat, location, scrolling }) => {
  const { mapItems } = useNavigation()
  const { getOnSale } = useSale()
  const { edgeNormaliser } = useShopify()

  const { menus, promotions: rawPromotions } = useStaticQuery(graphql`
    query SANITY_SETTINGS_DESKTOP_MAIN_MENU {
      menus: sanitySettingMenus {
        desktopHeaderMain {
          items: _rawItems(resolveReferences: { maxDepth: 12 })
        }
      }
      promotions: allSanityPromotion {
        edges {
          node {
            title
            slug {
              current
            }
            promotion {
              desktopHeader: _rawDesktopHeader(resolveReferences: { maxDepth: 10 })
              mobileHeader: _rawMobileHeader(resolveReferences: { maxDepth: 10 })
              homepage: _rawHomepage(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    }
  `)

  const { desktopHeaderMain } = menus || {}

  const promotions = rawPromotions
    ? edgeNormaliser(rawPromotions).reduce(
        (result, item) =>
          item?.slug?.current
            ? {
                ...result,
                [item.slug.current]: { items: item?.promotion?.desktopHeader?.items },
              }
            : result,
        {}
      )
    : {}
  const saleName = getOnSale()

  const rawItems = saleName && promotions[saleName]?.items ? promotions[saleName]?.items : desktopHeaderMain?.items
  const items = useMemo(() => mapItems(rawItems), [rawItems, mapItems])

  const [active, setActive] = useState("")

  const handleHover = value => () => {
    setActive(value)
  }
  Component.displayName = name
  return <Component isSolid={isSolid} items={items} active={active} handleHover={handleHover} desktopNavigationFormat={desktopNavigationFormat} location={location} scrolling={scrolling} />
}
