import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

export const Outer = tw.div`
  relative flex-grow
`

export const Header = tw.header`
  flex items-center min-h-8 px-2 w-full relative
`

export const Inner = tw.div``

export const List = tw.div`
  flex flex-col flex-grow bg-white px-2 relative
`

export const StyledLink = styled(Link)`
  flex items-center justify-between w-full 
`

export const Item = tw.div`
  text-16 py-2-4 font-semibold tracking-1.5 uppercase border-b border-grey-dim flex items-center justify-between w-full 
`

export const Button = styled.button`
  ${tw`text-16 font-semibold tracking-1.5 uppercase flex items-center justify-between`}
  ${({ full }) => (full && tw`w-full`)}
`

export const SubList = styled.div`
  ${tw`bg-white absolute top-0 left-0 w-full h-full px-2 transform transition duration-300 mt-6`}
  ${({ active }) => (active ? tw`pointer-events-auto visible opacity-100 translate-x-0` : tw`pointer-events-none opacity-0 -translate-x-50`)}
`

export const SubHeading = tw.div`
  text-16 text-grey-dim py-1-8 font-normal tracking-1.5 border-b border-grey-dim
`

export const SubItem = tw.div`
  text-12 py-1-8 tracking-1.5 border-b border-grey-dim
`

export const Footer = tw.footer`
  mt-auto py-1-2 pb-1-2 flex items-center z-10
`

export const Close = styled.button`
  ${tw`mr-auto transform translate-x-0 absolute top-1/2 -translate-y-1/2 transition-all duration-300`}
  ${({ active }) => (active && tw`translate-x-3`)}
`

export const Back = tw.button`mr-2`

export const SearchButton = styled.button`
  ${tw`text-left w-full mb-2 flex items-center text-grey-avg pb-2 border-b border-black`}
  svg {
    ${tw`text-black mr-1`}
  }
`