import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

import { Icon } from "../../Icon/Icon"

export const StyledIcon = styled(Icon)`
  ${tw`mr-1 lg:mr-3`}
  svg {
    ${tw`w-auto h-auto hd:h-1-4`}
  }  
`

export const StyledLink = styled(Link)`
  ${({ layout }) => {
    if (layout === "desktop") return tw`hidden mlg:flex flex-row items-center`
    if (layout === "mobile") return tw`flex flex-row items-center`
  }}
`
