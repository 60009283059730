import React from "react"
import { useMessage } from "../../../hooks/useMessage"

interface MessageProps {
  name?: string;
  tags: any;
  layout: any;
  forceColour?: string;
}

export const withMessage = (Component: React.ComponentType<any>) =>
  ({ name = "Message", tags, layout, forceColour }: MessageProps) => {
    const { message, colour } = useMessage(tags)

    Component.displayName = name
    return <Component
      message={message}
      colour={colour}
      layout={layout}
      forceColour={forceColour}
    />
  }