import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useRoutes } from "../../../hooks/useRoutes"
import { useCore } from "../../../hooks/useCore"

export const withFooterContact = Component => ({ name = "FooterContact" }) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const { linkResolver } = useRoutes()
  const { global } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_FOOTER_CONTACT {
      global: sanityTemplateGlobal {
        footerContactTitleLink: _rawFooterContactTitleLink(resolveReferences: { maxDepth: 2 })
        footerContactChatText
        footerContactChatAvailableText
        footerContactCallText
        footerContactCall
        footerContactEmailText
        footerContactEmail
        footerContactStores: _rawFooterContactStores(resolveReferences: { maxDepth: 2 })
        footerContactStockist: _rawFooterContactStockist(resolveReferences: { maxDepth: 2 })
        footerContactIcons: _rawFooterContactIcons(resolveReferences: {maxDepth: 2})
      }
    }
  `)

  const {
    footerContactTitleLink,
    footerContactChatText,
    footerContactChatAvailableText,
    footerContactCallText,
    footerContactCall,
    footerContactEmailText,
    footerContactEmail,
    footerContactStores,
    footerContactStockist,
    footerContactIcons
  } = global || {}

  const titleLink = linkResolver(footerContactTitleLink)
  const storesLink = linkResolver(footerContactStores)
  const stockistLink = linkResolver(footerContactStockist)

  const handleChatClick = () => {
    
    isBrowser && window?.GorgiasChat ? window.GorgiasChat.open() : null
  }

  const isChatAvailable = () => {
    isBrowser && window?.GorgiasChat ? window.GorgiasChat.isBusinessHours() : false
  }

  const buttonLink = (link: any, type: string) => {
    if(type === "email" || type === "mail"){
      return `mailto:${link}`
    }
    if(type === "phone") {
      return `tel:${link}`
    }
  }

  Component.displayName = name
  return (
    <Component
      titleLink={titleLink}
      chatText={footerContactChatText}
      chatAvailableText={footerContactChatAvailableText}
      callText={footerContactCallText}
      call={footerContactCall}
      emailText={footerContactEmailText}
      email={footerContactEmail}
      storesLink={storesLink}
      stockistLink={stockistLink}
      handleChatClick={handleChatClick}
      isChatAvailable={isChatAvailable}
      footerContactIcons={footerContactIcons}
      buttonLink={buttonLink}
    />
  )
}

declare global {
  var GorgiasChat: any // eslint-disable-line
}
