import React from "react"
import { Link } from "gatsby"

import { withCartEmpty } from "./withCartEmpty"
import { Wrapper, Title } from "./CartEmptyStyles"
import { UnderlinedButton } from "../../Styled/Button"

export const CartDrawerEmpty = withCartEmpty(({ continueLink, emptyDrawerMessage }) => (
  <Wrapper>
    <Title>{emptyDrawerMessage}</Title>
    <div>
      <UnderlinedButton as={Link} to={continueLink.url}>
        <span>{continueLink.title}</span>
      </UnderlinedButton>
    </div>
  </Wrapper>
))
