import { graphql, useStaticQuery } from "gatsby"
import React from "react"

export const withFooterCopyright = Component => ({ name = "FooterCopyright", layout }) => {
  const { global } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_FOOTER_COPYRIGHT {
      global: sanityTemplateGlobal {
        footerCopyrightText
      }
    }
  `)

  const { footerCopyrightText } = global || {}

  const currentYear = new Date().getFullYear()

  const copy = footerCopyrightText.replace("${year}", currentYear)

  Component.displayName = name
  return <Component copy={copy} layout={layout} />
}
