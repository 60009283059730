import tw, { styled } from "twin.macro"

import { Announcement } from "./Announcement/Announcement"

export const StyledHeader = styled.header`
  ${tw`flex flex-col justify-center sticky top-0 z-10 left-0 w-full transition-all duration-300 px-2 xl:px-0 h-8 xl:h-9`}
  ${({ solid }) => (solid ? tw`bg-white text-black` : tw`bg-transparent text-white`)}}
  ${({ scrolling }) => (scrolling ? tw`top-0` : tw`top-4`)}}
`

export const HeaderPanel = tw.div`
  relative top-0
`

export const HeaderMasthead = tw.div`
  grid grid-cols-12
`

export const PanelLeft = tw.div`
  flex items-center col-span-4 xl:col-span-1
`

export const PanelMiddle = tw.div`
  flex flex-grow items-center justify-center col-span-4 xl:col-span-10
`

export const PanelRight = tw.div`
  flex items-center justify-end col-span-4 xl:col-span-1
`

export const DesktopAnnouncement = styled(Announcement)`
  ${tw`flex-1 flex w-full bg-black text-white py-2 h-auto z-10`}
`

export const MobileAnnouncement = styled(Announcement)`
  ${tw`mlg:hidden bg-white`}
`

export const MobileBrand = tw.div`
  mlg:hidden max-w-11-2 w-full flex items-center
`
export const HomeMargin = tw.div`
  -mb-8 xl:-mb-9
`
export const PageMargin = styled.div`
  ${({ enableAnnouncementBar }) => (enableAnnouncementBar && tw`mb-4`)}}
`
