import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useFunctions } from "../../hooks/useFunctions"

export const withSubscribe = Component => ({ name = "Subscribe", layout = "footer" }: any) => {
  const { customerSubscribe, loading, errors } = useFunctions()

  const { global } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_SUBSCRIBE {
      global: sanityTemplateGlobal {
        additionalSubscribeTitle
        additionalSubscribeDescription
        additionalSubscribeInputPlaceholder
        additionalSubscribeSubmitButtonText
        additionalSubscribeSubmittingButtonText
        additionalSubscribeSuccessMessage
        additionalSubscribeSuccessTitle
      }
    }
  `)

  const {
    additionalSubscribeTitle,
    additionalSubscribeDescription,
    additionalSubscribeInputPlaceholder,
    additionalSubscribeSubmitButtonText,
    additionalSubscribeSubmittingButtonText,
    additionalSubscribeSuccessMessage,
    additionalSubscribeSuccessTitle,
  } = global || {}

  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    const { customer } = await customerSubscribe(email)
    if (customer?.id) {
      setSuccess(true)
      setEmail("")
    }
  }

  const handleChange = ({ target: { value } }) => {
    setEmail(value)
  }

  Component.displayName = name
  return (
    <Component
      layout={layout}
      email={email}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      title={additionalSubscribeTitle}
      additionalSubscribeTitle={additionalSubscribeTitle}
      description={additionalSubscribeDescription}
      placeholder={additionalSubscribeInputPlaceholder}
      submitButtonText={additionalSubscribeSubmitButtonText}
      submittingButtonText={additionalSubscribeSubmittingButtonText}
      success={success}
      successMessage={additionalSubscribeSuccessMessage}
      successTitle={additionalSubscribeSuccessTitle}
    />
  )
}
