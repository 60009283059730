import React from "react"

import { withSearchForm } from "./withSearchForm"
import {
  CloseButton,
  Input,
  QuickSearch,
  QuickSearchItem,
  QuickSearchItems,
  QuickSearchLabel
} from "./SearchFormStyles"
import { Icon } from "../../Icon/Icon"

export const SearchForm = withSearchForm(
  ({ additionalSearchPlaceholderText, searchTerm, setSearchTerm, handleClose, searchQuickLinks }): JSX.Element => (
    <>
      <Input>
        <input
          aria-label="q"
          placeholder={additionalSearchPlaceholderText}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
        <CloseButton onClick={() => handleClose()}>
          <Icon name={"cross"} width={15} />
        </CloseButton>
      </Input>
      {searchQuickLinks?.length && (
        <QuickSearch>
          <QuickSearchLabel>
            Quick Links:
          </QuickSearchLabel>
          <QuickSearchItems>
            {searchQuickLinks?.map((item, index) => (
              <>
                <QuickSearchItem to={item?.url} key={index}>{item?.title}</QuickSearchItem>
              </>
            ))}
          </QuickSearchItems>
        </QuickSearch>
      )}
    </>
  )
)
