import React from "react"

import { List, ListItem, StyledLink } from "../NavigationHeaderDesktopStyles"
import { withNavigationHeaderDesktopMain } from "./withNavigationHeaderDesktopMain"
import { NavigationHeaderDesktopDropdown } from "./Dropdown/NavigationHeaderDesktopDropdown"

export const NavigationHeaderDesktopMain = withNavigationHeaderDesktopMain(({ isSolid, items, handleHover, active, desktopNavigationFormat, location, scrolling }) => (
  <List>
    {items?.map(item => (
      <ListItem
        key={item?.title}
        onMouseEnter={handleHover(item.title)}
        onMouseLeave={handleHover("")}
        onClick={handleHover("")}
        active={active === item.title}
        format={desktopNavigationFormat}
        titleHoverColour={item.titleHoverColour}
        isSolid={isSolid}
      >
        <StyledLink isSolid={isSolid} titleHoverColour={item.titleHoverColour} active={location?.pathname?.includes(item?.url) || active === item.title} to={item?.url}>
          {item?.title}
        </StyledLink>
        {item.items?.length > 0 ? (
          <NavigationHeaderDesktopDropdown
            item={item}
            active={active === item.title}
            scrolling={scrolling}
          />
        ) : null}
      </ListItem>
    ))}
  </List>
))
