import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useSettings } from "../../hooks/useSettings"

export const withSocialMedia = Component => ({ name = "SocialMedia", layout = "desktop" }) => {
  const {
    social: { channels: items },
  } = useSettings()

  const { global } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_SOCIAL {
      global: sanityTemplateGlobal {
        additionalSocialShareTitle
      }
    }
  `)

  const { additionalSocialShareTitle } = global || {}

  Component.displayName = name
  return <Component items={items} title={additionalSocialShareTitle} layout={layout} />
}
