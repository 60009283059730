import React, { useState } from "react"

export const WishlistContext: any = React.createContext({})

export const WishlistProvider = ({ children }): JSX.Element => {
  const [wishlist, setWishlist] = useState([])

  const values = {
    wishlist,
    setWishlist,
    count: wishlist.length,
  }

  return <WishlistContext.Provider value={{ ...values }}>{children}</WishlistContext.Provider>
}
