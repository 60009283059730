import React, { useMemo } from "react"
import { useShopify } from "../../../hooks/useShopify"

export const withSearchResultsCard = Component => ({ name = "SearchResultsCard", item, collection = undefined, prices }) => {
  const { imageNormaliser } = useShopify()

  const formattedItem = useMemo(() => ({
    images: item?.images?.map(image => imageNormaliser(image, 1000)),
    key: item.id,
    url: item.url,
    title: item.title,
    tags: item.tags,
    defaultVariant: item?.variants?.[0],
    handle: item.handle,
    id: item.id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [item])

 const livePrice = useMemo(() => prices?.find(price => price?.id == `gid://shopify/Product/${item?.id}`), [item?.id, prices])

  Component.displayName = name
  return <Component item={formattedItem} collection={collection} livePrice={livePrice } isPriceLoaded={!!livePrice} />
}
