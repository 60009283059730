import { useCallback } from "react"

import { useApp } from "./useApp"
import { useRoutes } from "./useRoutes"
import { useImage } from "./useImage"
import { useLocation } from "./useLocation"

export const useNavigation = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { linkResolver } = useRoutes()
  const { getFluidImage } = useImage()
  const { location } = useLocation()

  const mapUrl = useCallback(
    (item, type) =>
      type === "page"
        ? `${routes[item?._type?.toUpperCase().replace("PAGE", "").replace("ACCOUNT", "")] || "/"}${item?.slug?.current || ""}`
        : type === "product" || type === "collection"
        ? `${routes[type.toUpperCase()] || "/"}/${item?.shopify?.shopifyHandle || ""}`
        : type === "link"
        ? `${item}`
        : undefined,
    [routes]
  )

  const mapItems = useCallback(
    items =>
      items?.map(item => {
        const hideByMarket = item?.hideByMarket?.map(market => market?.toLowerCase())
        const currentLocation = location?.toLowerCase()
        if (hideByMarket?.includes(currentLocation)) return null

        const type = item?._type?.replace("navigation", "").toLowerCase()
        if (type === `feat`) {
          return {
            type,
            title: linkResolver(item.link).title,
            url: linkResolver(item.link).url,
            items: item?.items?.length > 0 ? mapItems(item.items) : [],
            image: { ...(getFluidImage(item?.image, { maxWidth: 700 }) || {}), url: linkResolver(item?.imageLink).url, alt: item?.image?.alt },
            giftCards: { ...item?.giftCards, link: linkResolver(item?.giftCards?.link) },
            featuredLink: linkResolver(item?.featuredLink),
          }
        }
        if (type === `sub`) {
          return {
            type,
            title: linkResolver(item.link).title || undefined,
            url: linkResolver(item.link).url || undefined,
            items: item?.items?.length > 0 ? mapItems(item.items) : [],
            titleHoverColour: item?.titleHoverColour,
          }
        }
        if (type === `twocolimage`) {
          return {
            type,
            title: item?.title || undefined,
            url: linkResolver(item.link).url || undefined,
            image: item?.image,
            ratio: item?.imageRatio,
          }
        }
        return {
          type,
          title: item?.title,
          url: mapUrl(item[type], type),
          titleHoverColour: item?.titleHoverColour,
          items: item?.items?.length > 0 ? mapItems(item.items) : [],
        }
      }),
    [location, mapUrl, linkResolver, getFluidImage]
  )

  return {
    mapItems,
  }
}
