import React from "react"

import { withNewsletter } from "./withNewsletter"
import { Content, StyledIcon } from "./NewsletterStyles"
import { Subscribe } from "../Subscribe/Subscribe"
import { ModalContentsWithLayout, ModalExternal } from "../Modal/Modal"

export const Newsletter = withNewsletter(({ isCookiesHidden, activeSubscribe, setActiveSubscribe }) => (
  <ModalExternal isOpen={activeSubscribe} setIsOpen={setActiveSubscribe}>
    <ModalContentsWithLayout layout={"newsletter"}>
      <Content isCookiesHidden={isCookiesHidden}>
        <StyledIcon title={"close newsletter"} name={"cross"} handleClick={setActiveSubscribe} />
        <Subscribe layout={"newsletter"} />
      </Content>
    </ModalContentsWithLayout>
  </ModalExternal>
))
