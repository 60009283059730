import React, { useCallback, useEffect } from "react"
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import { useApp } from "../../../hooks/useApp"

export const withInstant = Component => ({ name = "Instant" }: any) => {
  const { globalStateReducer } = useApp()
  const [{ activeSearch }, dispatch] = globalStateReducer

  useEffect(() => {
    if (activeSearch) {
      disableBodyScroll(document?.querySelector('.instant-search'))
      // defer 500ms to wait actual DOM updates.
      setTimeout(function () {
        document.querySelector(`input[aria-label="q"]`).focus()
      }, 500)
    }
  }, [activeSearch])

  const handleClose = useCallback(() => {
    dispatch({
      type: "HIDE_SEARCH",
    })
    clearAllBodyScrollLocks()
  }, [dispatch])

  useEffect(() => {
    if (activeSearch === false) {
      clearAllBodyScrollLocks()
    }
  }, [activeSearch])

  Component.displayName = name
  return (
    <Component
      activeSearch={activeSearch}
      handleClose={handleClose}
    />
  )
}
