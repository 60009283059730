import tw, { styled } from "twin.macro"

const styles = {
  colour: {
    default: tw`text-black hover:underline`,
    yellow: tw`bg-black text-white`,
    grey: tw`bg-grey text-white md:hover:underline`,
    black: tw`bg-black text-white`,
    white: tw`bg-white text-black md:hover:underline`,
    blackYellow: tw`bg-black text-white border-2 border-white hover:bg-white hover:text-black md:hover:underline`,
    blackWhite: tw`bg-black text-white hover:bg-black`,
    blackOutline: tw`border-2 border-black hover:bg-black hover:text-white`,
    yellowOutline: tw`border-2 border-white text-white hover:bg-white hover:text-white`,
    transparent: tw`bg-transparent md:hover:underline`,
    transparentWhite: tw`bg-transparent text-white md:hover:underline`,
    transparentBlack: tw`bg-transparent text-black font-semibold border-b-2 border-black uppercase hover:text-white hover:border-white tracking-widest`,
    transparentYellow: tw`bg-transparent text-white font-semibold border-b-2 border-white uppercase hover:text-black hover:border-black tracking-widest`,
    underlinedLink: tw`bg-transparent text-black`,
    underlinedLinkYellow: tw`bg-transparent text-white font-semibold border-b-2 border-white uppercase hover:text-black hover:border-black tracking-widest`,
    underlinedLinkWhite: tw`text-white border-white`,
    blackArrowIcon: tw`text-black flex items-center gap-1`,
    whiteArrowIcon: tw`text-white flex items-center gap-1`,
  },
  size: {
    primary: tw`py-1 px-2`,
    large: tw`py-1 px-2`,
    topBottom: tw`py-1`,
    wide: tw`px-1-6 py-0-8`,
    none: tw`p-0`,
  },
  fontSize: {
    primary: tw`text-12`,
    large: tw`text-16`,
  },
  stretch: tw`w-full h-full`,
  wide: tw`w-full`,
  state: {
    disabled: tw`pointer-events-none opacity-50`,
  },
  border: {
    underlinedLink: tw`bg-black`,
    underlinedLinkYellow: tw`bg-white`,
    underlinedLinkWhite: tw`bg-white`,
  },
}

export const StyledButton = styled.button<ButtonProps>`
  ${tw`select-none outline-none leading-none inline-block text-center text-12 focus:outline-none max-w-full transition-all duration-300`}
  ${({ size }) => (size && styles.size[size]) || styles.size[`primary`]}
  ${({ stretch }) => stretch && styles.stretch}
  ${({ wide }) => wide && styles.wide}
  ${({ colour }) => (colour && styles.colour[colour]) || styles.colour[`black`]}
  ${({ disabled }) => disabled && styles.state.disabled}
  ${({ uppercase }) => uppercase && tw`uppercase tracking-1.5`}
  ${({ active }) => active && tw`underline`}
  ${({ offset }) => offset && tw`mr-1`}
  span {
    ${tw`block`}
  }
`

export const UnderlinedButton = styled.button<UnderlinedButtonProps>`
  ${tw`relative text-12`}
  ${({ colour }) => (colour && styles.colour[colour]) || styles.colour[`underlinedLink`]}
  span {
    ${({ important }) => (important === "true" ? tw`text-red text-18 leading-none  uppercase font-medium` : tw`text-12`)}
    ${({ size }) => (size && styles.fontSize[size]) || styles.fontSize[`primary`]}
    &:after {
      content: "";
      ${({ important }) => (important === "true" ? tw`bg-red h-0-2` : tw`h-0-1`)}
      ${({ colour }) => colour && styles.border[colour]}
      ${tw`absolute  -bottom-0-4 left-0 w-full `}
    }
  }
`

type ButtonProps = {
  colour?: "yellow" | "grey" | "black" | "white"
  size?: "primary" | "secondary" | "tertiary" | "filter"
  disabled?: boolean
  stretch?: boolean
  wide?: boolean
  active?: boolean
}

type UnderlinedButtonProps = {
  important?: "true"
}
