import tw, { styled } from "twin.macro"

const styles = {
  layout: {
    features: tw`pl-7-2 relative`,
    header: tw`px-2 relative`,
  },
  border: {
    faq: tw`border-t border-black border-opacity-25`,
    features: tw`border-t border-black border-opacity-25`,
    info: tw`border-t border-black border-opacity-25`,
    text: tw`border-t border-black border-opacity-25`,
    filter: tw`border-t border-black border-opacity-25`,
    header: tw`border-b border-grey`,
  },
  borderBottom: {
    features: tw`border-b border-black border-opacity-25`,
    info: tw`border-b border-black border-opacity-25`,
    text: tw`border-b border-black border-opacity-25`,
    filter: tw`border-b border-black border-opacity-25`,
  },
  title: {
    faq: tw`justify-between py-0-8 md:py-1-6`,
    features: tw`justify-between py-2-8`,
    info: tw`justify-between py-0-8 px-1`,
    text: tw`justify-between py-0-8 px-1`,
    filter: tw`justify-between py-2-6`,
    header: tw`justify-between py-1-2`,
  },
  size: {
    faq: tw`text-12`,
    features: tw`text-12 font-medium uppercase `,
    info: tw`text-12`,
    text: tw`text-12`,
    filter: tw`text-12 font-medium uppercase `,
    header: tw`text-12`,
  },
  icon: {
    faq: tw`w-1-2 h-1-2 md:w-1-4 md:h-1-4`,
    features: tw`w-1-2 h-1-2 md:w-1-4 md:h-1-4`,
    info: tw`hidden`,
    text: tw``,
    filter: tw`w-1-2 h-1-2`,
    header: tw`hidden`,
  },
  content: {
    info: tw`text-12 px-1 pb-1-2`,
    text: tw`text-12 px-1 pb-1-2`,
    filter: tw`pt-0-2 pb-0-8`,
  },
}

export const Wrapper = styled.div<LayoutProps>`
  ${tw`w-full`}
  ${({ layout }) => (layout ? styles.border[layout] : null)}
  ${({ layout }) => (layout ? styles.layout[layout] : null)}
  :last-of-type {
    ${({ layout }) => (layout ? styles.borderBottom[layout] : null)}
    padding-bottom: 1rem;
  }
`
export const TitleWrapper = styled.button<LayoutProps>`
  ${tw`w-full flex items-center focus:outline-none`}
  ${({ layout }) => (layout ? styles.title[layout] : null)}
  span {
    svg {
      ${({ layout }) => (layout ? styles.icon[layout] : null)}
    }
  }
`
export const Title = styled.h3<LayoutProps>`
  ${tw`text-left`}
  ${({ layout }) => (layout ? styles.size[layout] : null)}
`
export const Content = styled.div<LayoutProps>`
  ${({ layout }) => (layout ? styles.content[layout] : null)}
`

export const IconOuter = tw.div`
  relative
`

export const IconInner = styled.div<ActiveProps>`
  ${tw`absolute top-1/2 right-0 transform -translate-y-1/2`}
  ${({ active }) => (active === "true" ? tw`opacity-100 visible pointer-events-auto` : tw`opacity-0 invisible pointer-events-none`)}
`

export const PrefixIcon = tw.div`
  absolute left-0 w-4 h-7-2 flex items-center
`

export const IconWrapper = tw.div`
  w-4 max-h-4
`

type ActiveProps = {
  active: "true" | "false"
}

type LayoutProps = {
  layout: "faq" | "features" | "info" | "text"
}
