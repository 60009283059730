import React, {useMemo} from "react"
import { graphql, useStaticQuery } from "gatsby"
import {useNavigation} from "../../../hooks/useNavigation";
import { useSearch } from "@usereactify/search"

export const withSearchForm = Component => ({ name = "SearchForm", handleClose }) => {
  const { mapItems } = useNavigation()
  const { searchTerm, setSearchTerm } = useSearch()

  const { searchForm } = useStaticQuery(graphql`
    query SANITY_PAGE_SEARCH_FORM {
      searchForm: sanityPageSearch {
        additionalSearchPlaceholderText
        searchQuickLinks {
          items: _rawItems(resolveReferences: {maxDepth: 8})
        }
      }
    }
  `)

  const { additionalSearchPlaceholderText } = searchForm || {}

  const items = useMemo(() => mapItems(searchForm?.searchQuickLinks?.items), [searchForm?.searchQuickLinks?.items, mapItems])

  Component.displayName = name
  return <Component
    searchTerm={searchTerm}
    setSearchTerm={setSearchTerm}
    additionalSearchPlaceholderText={additionalSearchPlaceholderText}
    searchQuickLinks={items}
    handleClose={handleClose}
  />
}
