import tw, { styled } from "twin.macro"

export const Outer = tw.div`
  pb-6 md:pb-0
`

export const Inner = styled.div`
  ${tw`grid grid-cols-2 gap-x-2 mb-1-6`}
  button {
    ${tw`px-1`}
  }
`

export const FreeShippingCallToAction = tw.p`
  text-center my-1-6 text-12 uppercase
`
