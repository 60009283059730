import React from "react"

import { withPaymentIcons } from "./withPaymentIcons"
import { List, ListItem } from "./PaymentIconsStyles"
import { Icon } from "../../Icon/Icon"

export const PaymentIcons = withPaymentIcons(({ icons }) => (
  <List>
    {icons?.map(icon => (
      <ListItem key={icon?.title}>
        <Icon name={icon?.icon} />
      </ListItem>
    ))}
  </List>
))
