import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

export const Input = styled.div`
  ${tw`flex-1 relative`}
  input {
    ${tw`bg-white p-0 py-2 text-18 tracking-normal placeholder-black w-full placeholder-opacity-100 border-0 border-b border-black`}

    ::placeholder {
      ${tw`text-grey`}
    }
  }
`
export const CloseButton = styled.button`
  ${tw`absolute top-1/2 right-0 transform -translate-y-2/4`}
`

export const QuickSearch = tw.div`
  mt-1 mb-1 text-12 flex
`

export const QuickSearchLabel = tw.div`
  font-semibold mr-1
`

export const QuickSearchItems = tw.div`
  flex
`

export const QuickSearchItem = styled(Link)`
  ${tw`underline`}
  :not(:last-of-type) {
    ${tw`mr-1`}
  }
`
