import React from "react"
import { Link } from "gatsby"

import { withNavigationHeaderDesktopDropdown } from "./withNavigationHeaderDesktopDropdown"
import { ImageVideo } from "../../../../../ImageVideo/ImageVideo"
import { Inner, Outer, NavigationList, List, ListItem, SubList, SubListItem, ImageHeading } from "./NavigationHeaderDesktopDropdownStyles"

export const NavigationHeaderDesktopDropdown = withNavigationHeaderDesktopDropdown(({ items, active, scrolling, enableAnnouncementBar }) => (
  <Outer active={active} scrolling={scrolling} enableAnnouncementBar={enableAnnouncementBar}>
    <Inner>
      <NavigationList>
        {items?.map((item, index) => (
          <List key={`${item?.title}${index}`}>
            {item?.type === "twocolimage" ? (
              <div style={{ "display": "block" }}>
                {item?.url ? (
                  <Link to={item?.url}>
                    <ImageVideo  imageVideo={item?.image} ratio={item?.ratio} />
                    {item?.title && <ImageHeading>{item?.title}</ImageHeading>}
                  </Link>
                ): (
                  <div>
                    <ImageVideo  imageVideo={item?.image} ratio={item?.ratio} />
                    {item?.title && <ImageHeading>{item?.title}</ImageHeading>}
                  </div>
                )}
              </div>
            ) : (
                <>
                  <ListItem>{item?.url ? <Link to={item?.url}>{item?.title}</Link> : item?.title}</ListItem>
                  <SubList>
                    {item.items.map((item, index) => (
                      <>
                        {item?.type !== "sub" && <SubListItem key={`${item?.title}${index}`}>{item?.url ? <Link to={item?.url}>{item?.title}</Link> : item?.title}</SubListItem>}
                        {item?.type === "sub" && (
                          <>
                            <ListItem>{item?.title}</ListItem>
                            {item?.items.map((item, index) => (
                              <SubListItem key={index}>{item?.url ? <Link to={item?.url}>{item?.title}</Link> : item?.title}</SubListItem>
                            ))}
                          </>
                        )}
                      </>
                    ))}
                  </SubList>
                </>
              )}
          </List>
        ))}
      </NavigationList>
    </Inner>
  </Outer>
))
