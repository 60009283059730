import React from "react"
import { useApp } from "../../../../../hooks/useApp"

export const withNavigationHeaderMobileSlide = Component => ({ name = "NavigationHeaderMobileSlide", mobileNavigationLayout }) => {
  const { globalStateReducer } = useApp()

  const [{ activeMenu }, dispatch] = globalStateReducer

  const handleCloseMenu = () => {
    dispatch({
      type: "CLOSE_MENU",
    })
  }

  Component.displayName = name
  return mobileNavigationLayout === "slide" ? <Component active={activeMenu} handleCloseMenu={handleCloseMenu} /> : null
}
