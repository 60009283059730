import React from "react"
import { Link } from "gatsby"

import { withCard } from "./withCard"
import { Wrapper, ImageWrapper, Details, Title, ShopNow, SliderWrapper } from "./CardStyles"
import { ImageWithHover } from "../../ImageWithHover/ImageWithHover"
import { Price } from "../Price/Price"
import { Badge } from "../Badge/Badge"
import { Message } from "../Message/Message"
import CardImageSlider from "../../CardImageSlider/CardImageSlider"

export const Card = withCard(({ images, featuredImage, hoverImage, url, title, tags, defaultVariant, layout, collection, hidePriceForGiftCard, priceType, handle, isSlider, isSmall, isPriceLoaded }) => (
  <Wrapper className={"group"}>
    <ImageWrapper state={{ collection }} as={Link} to={url} title={title} image={images?.length > 0 ? "true" : "false"}>
      <Badge tags={tags} layout={"card"} />
      {isSlider && !isSmall ?
        <SliderWrapper>
          <CardImageSlider images={images} handle={handle} featuredImage={featuredImage}></CardImageSlider>
        </SliderWrapper>
        : ( <ImageWithHover image={featuredImage} hoverImage={hoverImage} ratio={"8/11"} showBorder={false} /> )}
    </ImageWrapper>
    <Details layout={layout}>
      <Title state={{ collection }} as={Link} to={url} title={title} layout={layout}>
        {title}
      </Title>
      {defaultVariant && layout === "normal" && !hidePriceForGiftCard && isPriceLoaded ? <Price variant={defaultVariant} layout={priceType} /> : null}
      {layout === "separate" ? (
        <ShopNow as={Link} to={url} title={title}>
          SHOP NOW
        </ShopNow>
      ) : null}
      <Message tags={tags} layout={"card"} />
    </Details>
  </Wrapper>
))
