import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"
import { Link } from "gatsby"

export const Wrapper = tw.div`
  relative
`

export const ImageWrapper = styled.div<ImageWrapperProps>`
  ${tw`relative block bg-beige`}

  ${({ image }) => {
    if (image === "false")
      return css`
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath fill='%23767676' d='M500.5 345c-119.1 0-215.7 96.6-215.7 215.8 0 119.2 96.6 215.8 215.7 215.8S716.2 680 716.2 560.8c0-119.2-96.6-215.8-215.7-215.8zm-1.1 370c-84.7 0-153.3-68.7-153.3-153.3 0-84.7 68.6-153.3 153.3-153.3 84.7 0 153.3 68.7 153.3 153.3C652.6 646.4 584 715 499.4 715zm373.5-490.3H730l-53-108.3c-5.2-9.8-14.5-15.5-24.2-15.9-1-.1-2-.2-3-.2H357c-5.9 0-11.3 1.8-15.9 5-4.6 2.7-8.6 6.8-11.4 12.1l-53.5 107.3H194v-30.9c0-17.4-14.1-31.5-31.5-31.5S131 176.4 131 193.8v30.9h-4c-64.6 0-117 52.5-117 117.2v440.6c0 64.7 52.4 117.1 117.1 117.1H873c64.7 0 117.1-50.5 117.1-115.2V341.9c-.1-64.7-52.5-117.2-117.2-117.2zm58.6 557.8c0 32.4-26.2 58.6-58.5 58.6H127.1c-32.3 0-58.5-26.2-58.5-58.6V343.8c0-32.4 26.2-58.6 58.5-58.6h171v-.1c9.1-.9 17.7-6.5 22.7-15.8l53.2-107h259.1l52.4 106.9c5 9.5 13.9 15.1 23.2 15.9v.1H873c32.3 0 58.5 26.2 58.5 58.6v438.7zm-155-435.1c-17 0-30.7 13.7-30.7 30.7s13.7 30.7 30.7 30.7 30.7-13.8 30.7-30.7c0-17-13.7-30.7-30.7-30.7z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 40%;
        background-position: 50% 50%;
      `
  }}
`

export const Details = styled.div`
  ${tw`flex flex-col text-white z-10`}
  ${({ layout }) => {
    if (layout === "separate") return tw`pt-0-8`
    if (layout === "hide") return tw`hidden`
    if (layout === "normal") return tw`absolute bottom-0 w-full px-1 md:px-2 pb-2 justify-end text-left`
  }}
`

export const Title = styled.h3`
  ${tw`text-12 pr-1-6`}
`

export const ShopNow = styled(Link)`
  ${tw`mt-0-6 text-14 uppercase font-medium underline`}
`

export const SliderWrapper = styled.div`
  ${tw`relative`}
`

type ImageWrapperProps = {
  image: "true" | "false"
}
