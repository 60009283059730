import tw, { styled } from "twin.macro"

export const Wrapper = tw.span`
  flex flex-row items-center
`

export const PriceTag = styled.p<PriceTagProps>`
  ${tw`uppercase text-12 tracking-1.5`}
  ${({ sale }) => (sale ? tw`text-grey` : null)}
  ${({ strike }) => (strike ? tw`line-through mr-0-2` : null)}
`

export const Message = tw.p`
  text-12 my-0-4
`

type PriceTagProps = {
  sale?: boolean
  strike?: boolean
}
