import { useCallback, useContext, useState } from "react"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useQueries } from "./useQueries"
import { useShopify } from "./useShopify"
import { useCustomerLogout } from "./useCustomer"
import { useShop, useShopContext } from "./useShop"
import { useLocation } from "./useLocation"
import { CheckoutContext } from "../providers/checkout"

export const useCheckoutContext = () => {
  const checkoutData: any = useContext(CheckoutContext)
  return { ...checkoutData }
}

export const useCheckout = () => {
  const {
    helpers: { storage },
  } = useCore()
  
  const {
    queries: { GET_PRODUCTS_BY_HANDLE },
  } = useQueries()

  const {
    mutations: {
      CHECKOUT_CREATE,
      CHECKOUT_ATTRIBUTES_UPDATE,
      CHECKOUT_SHIPPING_ADDRESS_UPDATE,
      CHECKOUT_EMAIL_UPDATE,
      CHECKOUT_DISCOUNT_APPLY,
      CHECKOUT_GIFTCARDS_APPEND,
      CHECKOUT_CUSTOMER_ASSOCIATE,
      CHECKOUT_DISCOUNT_CODE_REMOVE,
    },
    queries: { GET_CHECKOUT, GET_SHIPPING_RATES },
  } = useQueries()

  const {
    config: {
      settings: {
        keys,
        constraints: { productMetafields },
      },
    },
  } = useApp()

  const { shop } = useShopContext()

  const { checkout, setCheckout: saveCheckout, setInitializedLoading } = useCheckoutContext()

  const { checkoutNormaliser, useMutation, useQuery, client } = useShopify()
  const { logoutCustomer } = useCustomerLogout()
  const { getShop } = useShop()
  const { shopifyStore, shopifyStoreDomain } = useLocation()
  const [errors, setErrors] = useState(null)
  const [loading, setLoading] = useState(false)
  const checkoutId = storage.get(keys?.checkout)

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkoutAttributeUpdate] = useMutation(CHECKOUT_ATTRIBUTES_UPDATE)
  const [checkoutCustomerAssociate] = useMutation(CHECKOUT_CUSTOMER_ASSOCIATE)
  const [checkoutShippingAddressUpdate] = useMutation(CHECKOUT_SHIPPING_ADDRESS_UPDATE)
  const [checkoutEmailUpdate] = useMutation(CHECKOUT_EMAIL_UPDATE)
  const [checkoutDiscountApply] = useMutation(CHECKOUT_DISCOUNT_APPLY)
  const [checkoutDiscountRemove] = useMutation(CHECKOUT_DISCOUNT_CODE_REMOVE)
  const [checkoutGiftcardAppend] = useMutation(CHECKOUT_GIFTCARDS_APPEND)

  const { refetch: getCheckoutQuery } = useQuery(GET_CHECKOUT, { fetchPolicy: "no-cache", skip: true })
  const { refetch: getShippingRatesQuery } = useQuery(GET_SHIPPING_RATES, { fetchPolicy: "no-cache", skip: true })

  const getCheckout = useCallback(async () => {
    try {
      if (checkoutId) {
        const {
          data: { node: checkout },
        } = await getCheckoutQuery({ countryCode: storage.get(keys?.country), checkoutId })

        return checkout
      }
      return false
    } catch (error) {
      console.error(error)
    }
  }, [checkoutId, getCheckoutQuery])

  const setCheckout = useCallback(
    checkout => {
      try {
        saveCheckout(checkoutNormaliser(checkout))
        storage.set(keys?.checkout, checkout?.id)
      } catch (error) {
        console.error(error)
      }
    },
    [saveCheckout, checkoutNormaliser, keys?.checkout, storage]
  )

  const createCheckout = useCallback(
    async (presentmentCurrencyCode = "AUD", forceNew = false) => {
      try {
        const existingCheckout = !forceNew && (await getCheckout())
        const isCorrectMarket = existingCheckout?.buyerIdentity?.countryCode === storage.get(keys?.country)

        if (!isCorrectMarket || forceNew || !existingCheckout?.id || existingCheckout?.completedAt !== null || Object.keys(existingCheckout).length < 1) {
          const {
            data: {
              checkoutCreate: { checkout },
            },
          } = await checkoutCreate({
            variables: { countryCode: storage.get(keys?.country), input: { presentmentCurrencyCode: null } },
          })
          if (checkout) {
            setCheckout(checkout)
            setInitializedLoading(false)
          }
        } else {
          setCheckout(existingCheckout)
          setInitializedLoading(false)
        }
        getShop()
      } catch (error) {
        storage.remove(keys?.checkout)
        console.error(error)
      }
    },
    [getCheckout, setCheckout, checkoutCreate, getShop, keys?.checkout, storage, setInitializedLoading, storage, keys?.country]
  )

  const updateAttributes = useCallback(
    async input => {
      const {
        data: { checkoutAttributesUpdateV2: data },
      } = await checkoutAttributeUpdate({
        variables: { countryCode: storage.get(keys?.country), checkoutId, input },
      })
      setCheckout(data?.checkout)
    },
    [checkoutAttributeUpdate, setCheckout, checkoutId, storage, keys?.country]
  )

  const updateCustomer = useCallback(
    async customerAccessToken => {
      const {
        data: { checkoutCustomerAssociateV2: data },
      } = await checkoutCustomerAssociate({
        variables: { countryCode: storage.get(keys?.country), checkoutId, customerAccessToken },
      })
      setCheckout(data?.checkout)
    },
    [checkoutCustomerAssociate, setCheckout, checkoutId, storage, keys?.country]
  )

  const updateShippingAddress = useCallback(
    async input => {
      const {
        data: { checkoutShippingAddressUpdateV2: data },
      } = await checkoutShippingAddressUpdate({
        variables: {
          countryCode: storage.get(keys?.country), 
          checkoutId,
          shippingAddress: {
            firstName: input?.firstName,
            lastName: input?.lastName,
            address1: input?.address1,
            address2: input?.address2,
            city: input?.city,
            country: input?.country,
            phone: input?.phone,
            province: input?.province,
            zip: input?.zip,
          },
        },
      })
      setCheckout(data?.checkout)
    },
    [checkoutShippingAddressUpdate, setCheckout, checkoutId, storage, keys?.country]
  )

  const updateEmail = useCallback(
    async email => {
      const {
        data: { checkoutEmailUpdateV2: data },
      } = await checkoutEmailUpdate({
        variables: { countryCode: storage.get(keys?.country), checkoutId, email },
      })
      setCheckout(data?.checkout)
    },
    [checkoutEmailUpdate, setCheckout, checkoutId, storage, keys?.country]
  )

  const applyDiscountCode = useCallback(
    async discountCode => {
      setLoading(true)
      const {
        data: { checkoutDiscountCodeApplyV2: data },
      } = await checkoutDiscountApply({
        variables: { countryCode: storage.get(keys?.country), checkoutId, discountCode },
      })
      setLoading(false)
      if (!data.checkoutUserErrors.length) {
        storage.set(
          keys?.discounts,
          [...(storage.get(keys?.discounts) || []), discountCode].filter((value, index, self) => self.indexOf(value) === index)
        )
        setCheckout(data?.checkout)
        setErrors(null)
      } else {
        setErrors(data.checkoutUserErrors)
      }
    },
    [checkoutDiscountApply, setCheckout, checkoutId, keys?.discounts, storage, keys?.country]
  )

  const removeDiscountCode = useCallback(async () => {
    setLoading(true)
    const {
      data: { checkoutDiscountCodeRemove: data },
    } = await checkoutDiscountRemove({
      variables: { countryCode: storage.get(keys?.country), checkoutId },
    })
    setLoading(false)
    if (!data.checkoutUserErrors.length) {
      storage.set(keys?.discounts, [])
      setCheckout(data?.checkout)
    } else {
      setErrors(data.checkoutUserErrors)
    }
  }, [checkoutDiscountRemove, setCheckout, checkoutId, keys?.discounts, storage, keys?.country])

  const applyGiftCardCode = useCallback(
    async giftCardCode => {
      const {
        data: { checkoutGiftCardsAppend: data },
      } = await checkoutGiftcardAppend({
        variables: { countryCode: storage.get(keys?.country), checkoutId, giftCardCodes: [giftCardCode] },
      })

      if (!data.checkoutUserErrors.length) {
        setCheckout(data.checkout)
        return this
      } else {
        return data
      }
    },
    [checkoutGiftcardAppend, setCheckout, checkoutId, storage, keys?.country]
  )

  const getShippingRates = useCallback(async () => {
    const {
      data: { node: checkout },
    } = await getShippingRatesQuery({ countryCode: storage.get(keys?.country), id: checkoutId })

    setCheckout(checkout)
    return checkout
  }, [getShippingRatesQuery, setCheckout, checkoutId, storage, keys?.country])

  const applyDiscounts = useCallback(async () => {
    const discountCodes = storage.get(keys?.discounts) || []
    for (const discountCode of discountCodes) {
      await applyDiscountCode(discountCode)
    }
  }, [applyDiscountCode, keys?.discounts, storage])

  const duplicateCheckout = useCallback(
    async (changes = {}) => {
      const { currencyCode, customAttributes, lineItems, note, email, shippingAddress } = checkout
      const discounts = storage.get(keys?.discounts) || []

      const {
        data: { checkoutCreate: data },
      } = await checkoutCreate({
        variables: {
          countryCode: storage.get(keys?.country), 
          input: {
            ...(email && { email }),
            ...(note && { note }),
            ...(customAttributes && {
              customAttributes:
                customAttributes?.map(({ key, value }) => ({
                  key,
                  value,
                })) || [],
            }),
            presentmentCurrencyCode: currencyCode,
            lineItems:
              lineItems?.map(item => ({
                variantId: item?.variant.id,
                quantity: item?.quantity || 1,
                customAttributes:
                  item?.customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })) || [],
              })) || [],
            shippingAddress: shippingAddress
              ? {
                  address1: shippingAddress.address1,
                  address2: shippingAddress.address2,
                  city: shippingAddress.city,
                  company: shippingAddress.company,
                  country: shippingAddress.country,
                  firstName: shippingAddress.firstName,
                  lastName: shippingAddress.lastName,
                  phone: shippingAddress.phone,
                  province: shippingAddress.province,
                  zip: shippingAddress.zip,
                }
              : undefined,
            ...changes,
          },
        },
      })
      if (!discounts.length) setCheckout(data?.checkout)
      if (discounts.length) applyDiscounts()
    },
    [checkout, checkoutCreate, setCheckout, applyDiscounts, keys?.discounts, storage, keys?.country]
  )

  const migrateCheckout = useCallback(
    async (changes = {}) => {
      const { lineItems, note, email, customAttributes, shippingAddress } = checkout
      const discounts = storage.get(keys?.discounts) || []

      if (lineItems?.length) {
        const mappedLineItems =
          lineItems?.map(item => ({
            handle: item?.variant?.product?.handle,
            sku: item?.variant?.sku,
            quantity: item?.quantity,
            customAttributes: item?.customAttributes,
          })) || []

        const { data: matchedProducts } = await client.query({
          query: GET_PRODUCTS_BY_HANDLE(mappedLineItems.map(product => product?.handle)),
          variables: {
            countryCode: storage.get(keys?.country), 
            firstImages: 0,
            metafieldIdentifiers: productMetafields,
            firstVariants: 100,
          },
        })

        const migratedLineItems =
          mappedLineItems.map(lineItem => ({
            variantId: matchedProducts[`product${lineItem?.handle?.replace(/-/g, "")}`]?.variants?.edges
              ?.filter(({ node }) => node?.sku === lineItem?.sku)
              .map(({ node }) => node?.id)[0],
            quantity: lineItem?.quantity,
            customAttributes: lineItem?.customAttributes?.map(({ key, value }) => ({
              key,
              value,
            })),
          })) || []

        const {
          data: { checkoutCreate: data },
        } = await checkoutCreate({
          variables: {
            countryCode: storage.get(keys?.country), 
            input: {
              ...(email && { email }),
              ...(note && { note }),
              ...(customAttributes && {
                customAttributes:
                  customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })) || [],
              }),
              presentmentCurrencyCode: shop?.currencyCode,
              lineItems: migratedLineItems,
              shippingAddress: shippingAddress || undefined,
              ...changes,
            },
          },
        })
        if (!discounts.length) setCheckout(data?.checkout)
        if (discounts.length) applyDiscounts()
        getShop()
        logoutCustomer()
      } else {
        createCheckout(shop?.currencyCode, true)
      }
    },
    [
      checkout,
      createCheckout,
      checkoutCreate,
      setCheckout,
      applyDiscounts,
      logoutCustomer,
      getShop,
      client,
      GET_PRODUCTS_BY_HANDLE,
      keys?.discounts,
      shop?.currencyCode,
      storage,
      keys?.country,
    ]
  )

  const updateCurrency = useCallback(
    async presentmentCurrencyCode => {
      await duplicateCheckout({
        presentmentCurrencyCode: presentmentCurrencyCode,
      })
      getShop()
    },
    [duplicateCheckout, getShop]
  )

  const checkoutUrl = checkout.webUrl ? checkout.webUrl.replace(`${shopifyStore}.myshopify.com`, shopifyStoreDomain) : ""

  return {
    checkoutUrl,
    loading,
    errors,
    createCheckout,
    getCheckout,
    setCheckout,
    migrateCheckout,
    updateCurrency,
    updateAttributes,
    updateShippingAddress,
    updateEmail,
    updateCustomer,
    applyDiscountCode,
    removeDiscountCode,
    applyGiftCardCode,
    getShippingRates,
  }
}
