import tw, { styled } from "twin.macro"

export const Outer = styled.section<ActiveProps>`
  ${tw`pointer-events-none absolute w-screen left-0 hidden md:block transform transition-all duration-300 text-black font-body bg-white normal-case z-50 opacity-0 -translate-y-2 top-full`}
  ${({ active }) => (active && tw`pointer-events-auto opacity-100 translate-y-0`)}
`

export const Inner = tw.div`
  max-w-xl mx-auto px-7-5 pb-4
`

export const NavigationList = tw.div`
  flex flex-row justify-start px-1
`

export const List = styled.div`
  ${tw`py-2 px-0-8 max-w-1/4 w-full`}
`

export const ListItem = styled.div`
  ${tw`text-12 hd:text-14 font-semibold py-0-2 my-0-2 mb-2 tracking-normal hover:underline`}
  :not(:first-of-type) {
    ${tw`mt-2`}
  }
`

export const SubList = tw.div``

export const SubListItem = styled.div`
  ${tw`text-12 hd:text-14 font-normal tracking-normal text-black py-0-2 my-0-2 hover:underline`}
`

export const ImageHeading = tw.div`
  mt-1 text-14 font-serif font-normal tracking-0.03 leading-14
`

type ActiveProps = {
  active: boolean
}
