import tw, { styled, css } from "twin.macro"

export const Wrapper = styled.div<Props>`
  ${tw`text-12`}
  ${({ layout }) => {
    if (layout === "card") return tw`absolute z-10 top-2 left-2 py-0-8 px-1-6`
    if (layout === "product") return tw`inline-block py-0-5 px-0-8 mr-1-6 mb-2-4`
  }}
  ${({ colour }) => {
    if (colour === "black") {
      return tw`bg-black text-white`
    } else if (colour === "grey") {
      return tw`bg-grey text-white`
    } else if (colour === "red") {
      return tw`bg-red text-white`
    } else {
      return css`
        color: ${colour};
        padding-left: 0;
        padding-right: 0;
      `
    }
  }}
`

type Props = {
  layout: "card" | "product"
  colour: string
}
