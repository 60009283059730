import tw, { styled } from "twin.macro"
import { Body } from "../Styled/Text"

export const Wrapper = styled.div`
  ${({ layout }) => {
    if (layout === "desktop") return tw`hidden md:flex`
    if (layout === "mobile") return tw`flex md:hidden w-4/12`
  }}
  ${tw`flex-col`}
`

export const Title = styled(Body)`
  ${tw`block py-0-5 mb-0-6 text-black`}
`

export const List = tw.div`
  flex items-center flex-wrap lg:gap-1-6
`

export const ListItem = styled.div`
  ${tw`w-1/3 lg:w-auto py-0-8`}
`
// ${tw`mr-1-6 py-0-8`}
