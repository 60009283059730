import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import { Icon } from "../../Icon/Icon"

export const Wrapper = tw.div`
  flex flex-wrap lg:gap-2
`

export const Option = tw.div`
  flex flex-col w-4/12 lg:w-auto lg:flex-row items-center lg:items-start mb-2 text-center lg:text-left px-2 lg:px-0
`

export const Title = tw.div`
  text-12 lg:text-16 mt-0-8 lg:mt-0 mb-0 lg:mb-0-4
`

export const Description = tw.div`
  text-10 text-grey-dim leading-19
`

export const Content = tw.div``

export const StyledIcon = styled(Icon)`
  ${tw`lg:mr-2 border border-white rounded-full w-4 h-4 flex items-center justify-center text-white`}
`

export const StyledLink = styled(Link)`
  ${tw`flex flex-row items-center`}
`
