import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useNavigation } from "../../hooks/useNavigation"

export const withFooter = Component => ({ name = "Footer" }: any) => {
  const { mapItems } = useNavigation()

  const { footer } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_FOOTER_TITLE {
      footer: sanityTemplateGlobal {
        additionalSubscribeTitle
        footerMegaNavigation {
            items: _rawItems(resolveReferences: {maxDepth: 8})
        }
        footerBottomNavigation {
            items: _rawItems(resolveReferences: {maxDepth: 8})
        }
      }
    }
  `)

  const { additionalSubscribeTitle } = footer || {}

  const items = useMemo(() => mapItems(footer?.footerMegaNavigation?.items), [footer?.footerMegaNavigation?.items, mapItems])

  const footerBottomNavigation = useMemo(() => mapItems(footer?.footerBottomNavigation?.items), [footer?.footerBottomNavigation?.items, mapItems])

  Component.displayName = name
  return <Component subscribeTitle={additionalSubscribeTitle} footerMegaNavigation={items} footerBottomNavigation={footerBottomNavigation} />
}
